import { IResponseSuccess } from './Api.service';
import { AxiosResponse } from 'axios';
import { Service } from './Services';

// Interfaces
import { IEquipamento } from './Equipamentos.service';

// API Error;
import { ApiResponseError } from '../Classes/ApiResponseError'

// Validate
import {  newKitEquipamentoSchema, editKitEquipamentoSchema } from '../Utils/validations/KitEquipamentos.schema';

export interface IKitsEquipamento{
  readonly id: number;
  nome: string;
  obs?: string;
  active: number;
  user_id: number;
  created_at: string;
  updated_at: string;
  equipamentos?: IEquipamento[]
}

export class KitEquipamentosService extends Service<IKitsEquipamento>{


  constructor(path: string){
    super(path);
    this.path = path
  }
  

  public async getKitEquipamentos(): Promise<AxiosResponse<IResponseSuccess<IKitsEquipamento[]>>> {
    try {
      const response = await this.getAll();
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async getKitEquipamentosDisponiveis(): Promise<AxiosResponse<IResponseSuccess<IKitsEquipamento[]>>> {
    try {
      const response = await this.index(this.path+"/find/disponiveis");
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }


  

  public async getKitEquipamento(id: number): Promise<AxiosResponse<IResponseSuccess<IKitsEquipamento>>> {
    try {
      const response = await this.getOne(id);
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }


  public async getKitEquipamentoByEquipamentoID(id: number): Promise<AxiosResponse<IResponseSuccess<IKitsEquipamento[]>>> {
    try {
      const response = await this.index(`${this.path}/equipamento/${id}`);
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async editKitEquipamento(Dados: IKitsEquipamento): Promise<AxiosResponse<IResponseSuccess<IKitsEquipamento>>> {
    try {
      const data = await editKitEquipamentoSchema<IKitsEquipamento>(Dados);
      const response = await this.edit(data);
      return response;
    } catch (error) {
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async newKitEquipamento(Dados: IKitsEquipamento): Promise<AxiosResponse<IResponseSuccess<IKitsEquipamento>>> {
    try {

      const data = await newKitEquipamentoSchema<IKitsEquipamento>(Dados);
      const response = await this.new(data);
      return response;
    } catch (error) {
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }


} 



export const kitEquipamentosService = new KitEquipamentosService('/kit/equipamentos');