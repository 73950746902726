import React from "react";
import {
  Document,
  Page,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { useParams } from 'react-router-dom';

//* COMPONENT
import Header from '../../../Components/PDF/Header'
import Assinatura from '../../../Components/PDF/Assinatura'
import Emprestimo from './Emprestimo'
import Equipamentos from './Equipamentos'

//* CONTEXT
import useEmprestimo from "../../../Contexts/EmprestimosContext";
import useLoading  from "../../../Contexts/LoadingContext";

//* PROVIDER
import { EmprestimosProvider } from "../../../Contexts/EmprestimosContext";
import { LoadingProvider }  from "../../../Contexts/LoadingContext";

// Create styles
const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    padding: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
});


export default function PedidoPDF() {
  return (
    <LoadingProvider>
      <EmprestimosProvider>
          <RenderPDF />
      </EmprestimosProvider>
    </LoadingProvider>
  )
}


function RenderPDF(){
  const { id } = useParams();
  const { getEmprestimo, emprestimo } = useEmprestimo();
  const { loadingGet } = useLoading();

  React.useEffect(() => {
    let render = true;

    (async () => {
      if (render) {
        await getEmprestimo(id);
      }
    })();

    return function cleanup() {
      render = false;
    };

    //eslint-disable-next-line
  }, []);


  return (
    <PDFViewer style={{ flex: 1, width: "100%", height: "1000px" }}>
      { !loadingGet && emprestimo && <BasicDocument id={id} emprestimo={emprestimo}/> }
    </PDFViewer>
  )

}


// Create Document Component
function BasicDocument({ id, emprestimo }) {

  return (
    <Document title="Emprestimo" language="pt-BR">
      <Page size="A4" style={styles.page}>
        <Header />
        <Emprestimo emprestimo={emprestimo} />
        { emprestimo && emprestimo.kit && <Equipamentos emprestimo={emprestimo.kit} />}
        <Assinatura /> 
      </Page>
    </Document>
  );
}