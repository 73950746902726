import * as yup from "yup";
import Validate from "./Validate";

export async function editEquipamentoSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
    id: yup.number().required(),
    cod: yup.string().min(1, 'Código deve maior que 0.').max(999999, 'Código deve ser menor que 99999.').required("Requerido."),
		tipo_id: yup.number().min(1, 'Código deve maior que 0.').max(999999, 'Código deve ser menor que 99999.').required("Requerido."),
		nome: yup
			.string()
      .trim()
			.min(5, "No mínimo 5 Caracteres")
			.max(150, "No máximo 150 Caracteres")
			.required("Nome é obrigatório"),
    modelo: yup
			.string()
      .trim()
			.min(5, "No mínimo 5 Caracteres")
			.max(150, "No máximo 150 Caracteres")
			.required("Modelo é obrigatório"),
		unitario: yup.bool().required("Unitário é requerido.").default(true),
		qnt: yup.number().default(1),
    obs: yup.string().trim().notRequired().nullable(),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}

export async function newEquipamentoSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
    cod: yup.string().min(1, 'Código deve maior que 0.').max(999999, 'Código deve ser menor que 99999.').required("Requerido."),
    tipo_id: yup.number().min(1, 'Código deve maior que 0.').max(999999, 'Código deve ser menor que 99999.').required("Requerido."),
		nome: yup
			.string()
      .trim()
			.min(5, "No mínimo 5 Caracteres")
			.max(150, "No máximo 150 Caracteres")
			.required("Nome é obrigatório"),
    modelo: yup
			.string()
      .trim()
			.min(5, "No mínimo 5 Caracteres")
			.max(150, "No máximo 150 Caracteres")
			.required("Modelo é obrigatório"),
		unitario: yup.bool().required("Unitário é requerido.").default(true),
		qnt: yup.number().default(1),
    obs: yup.string().trim().notRequired().nullable(),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}
