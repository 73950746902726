import React from "react";
import { Paper } from "@mui/material";

//* COMPONENTES
import EquipamentosTable from "./Equipamentos.Table";

//* CONTEXT
import { EquipamentosProvider } from "../../Contexts/EquipamentosContext";
import { TableProvider } from "../../Contexts/TableContext";
import { LoadingProvider } from "../../Contexts/LoadingContext";

export default function Index() {
	return (
		<TableProvider>
			<LoadingProvider>
				<EquipamentosProvider>
					<Paper elevation={3}>
						<EquipamentosTable />
					</Paper>
				</EquipamentosProvider>
			</LoadingProvider>
		</TableProvider>
	);
}
