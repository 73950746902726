import React, {
  createContext,
  useState,
  useContext,
} from "react";
import PropTypes from "prop-types";

const LoadingContext = createContext({});

const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [loadingApi, setLoadingApi] = useState(true);
  const [loadingGet, setLoadingGet] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false); // SEMPRE DEVE SER FALSE


  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
        loadingSubmit,
        setLoadingSubmit,
        loadingApi,
        setLoadingApi,
        loadingGet,
        setLoadingGet
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useLoading() {
  return useContext(LoadingContext);
}

export { LoadingContext, LoadingProvider };
