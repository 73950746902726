import React, {
	createContext,
	useContext,
	useCallback,
} from "react";
import PropTypes from "prop-types";
import { useLocation } from 'react-router-dom';

//* LIST
import MenuList from "../Store/Menu";

const MenuContext = createContext({});

const MenuProvider = ({ children }) => {
  let location = useLocation();
	const [open, setOpen] = React.useState(false);
	const [Menus, SetMenus] = React.useState(() => MenuList);
	const [selected, setSelected] = React.useState(location.pathname);

	const toggleDrawerMenu = useCallback(() => {
		setOpen(!open);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

  
	const handleToggleSubMenu = useCallback((props) => () => {
		SetMenus(menu => {
			return menu.map(e => {
				return e.primary === props ? { ...e, isOpen: !e.isOpen } : e
			})
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);


	return (
		<MenuContext.Provider
			value={{
				open,
				setOpen,
				Menus,
				SetMenus,
				selected,
				setSelected,
				toggleDrawerMenu,
        handleToggleSubMenu,
			}}
		>
			{children}
		</MenuContext.Provider>
	);
};

MenuProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default function useAuth() {
	return useContext(MenuContext);
}

export { MenuContext, MenuProvider };
