import { IResponseSuccess } from './Api.service';
import { AxiosResponse } from 'axios';
import { Service } from './Services';

// Interfaces

// API Error;
import { ApiResponseError } from '../Classes/ApiResponseError';

// Validate
import {
  newPedidoKitSchema,
  newRevendedorKitSchema,
  updatePedidoKitSchema,
  updateRevendedorKitSchema,
} from '../Utils/validations/Emprestimo.schema';



// Interface
export interface IEmprestimo {
  readonly id: number;
  status: string;
  kit_id?: number;
  pedido_id?: number;
  cliente_id?: number;
  data_entrega?: string;
  data_devolucao?: string;
  obs?: string;
  user_id: number;
  created_at?: string;
  updated_at?: string;
}

export interface IEmprestimoNew {
  tipo: string;
  data_entrega?: string;
  data_devolucao?: string;
  pedido_id?: number;
  revendedor_id?: number;
  kit_id?: number;
}

export type IEmprestimoUpdate = { id: number } & IEmprestimoNew;

export class EmprestimosService extends Service<IEmprestimo> {
  constructor(path: string) {
    super(path);
    this.path = path;
  }

  public async all(): Promise<AxiosResponse<IResponseSuccess<IEmprestimo[]>>> {
    try {
      const response = await this.getAll();
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async getEmprestimosAbertos(): Promise<AxiosResponse<IResponseSuccess<IEmprestimo[]>>> {
    try {
      const response = await this.index(this.path+"/abertos");
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }


  public async getEmprestimo(
    id: number
  ): Promise<AxiosResponse<IResponseSuccess<IEmprestimo>>> {
    try {
      const response = await this.getOne(id);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }


  public async getEmprestimosByClienteID(
    cliente_id: number
  ): Promise<AxiosResponse<IResponseSuccess<IEmprestimo[]>>> {
    try {
      const response = await this.index(`${this.path}/revendedor/${cliente_id}`);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async getEmprestimosByKitID(
    kit_id: number
  ): Promise<AxiosResponse<IResponseSuccess<IEmprestimo[]>>> {
    try {
      const response = await this.index(`${this.path}/kit/${kit_id}`);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async getEmprestimosByEquipamentoID(
    equipamento_id: number
  ): Promise<AxiosResponse<IResponseSuccess<IEmprestimo[]>>> {
    try {
      const response = await this.index(`${this.path}/equipamento/${equipamento_id}`);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async editEmprestimo(
    Dados: IEmprestimoUpdate
  ): Promise<AxiosResponse<IResponseSuccess<IEmprestimo>>> {
    try {
      const data = Dados.tipo === "pedido" ? await updatePedidoKitSchema<any>(Dados) : await updateRevendedorKitSchema<any>(Dados);
      const response = await this.edit(data);
      return response;
    } catch (error) {
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async newEmprestimo(
    Dados: IEmprestimoNew
  ): Promise<AxiosResponse<IResponseSuccess<IEmprestimo>>> {
    try {
      const data = Dados.tipo === "pedido" ? await newPedidoKitSchema<any>(Dados) : await newRevendedorKitSchema<any>(Dados);
      const response = await this.new(data);
      return response;
    } catch (error) {
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }


  public async finalizaEmprestimo(
    id: number
  ): Promise<AxiosResponse<IResponseSuccess<IEmprestimo>>> {
    try {
      const response = await this.put(`/emprestimos/close/${id}`);
      return response;
    } catch (error) {
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }
}

export const emprestimosService = new EmprestimosService('/emprestimos');
