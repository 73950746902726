import moment from "moment";
import 'moment/locale/pt-br'

moment.locale('pt-br');


export function DateNow() : string{
  return moment().format()
}

export function DateFormat(date: string, format = 'YYYY-MM-DD') : string{
  return moment(date).format(format)
}


export function DateFormatInput(date: string) : string{
  return moment(date).format("YYYY-MM-DD")
}

export function DateTimeFormatInput(date: string) : string{
  return moment(date).format("YYYY-MM-DDTHH:mm")
}

export function AddDaysDate(date: string, days: number) : string {
  return moment(date).add(days, 'days').format("YYYY-MM-DD");
}

/**
 * Verifica se a data é anterior a hoje, se for retorna true;
 */
export function CompareDateBeforeToday(date: string) : boolean{
  
  if(date){
    const now = moment();
    const currDate = moment(date);
    return currDate.isBefore(now,"days");
  }

  return true;

}

/**
 * Verifica se o pagamento da entrada é hj ou antes de hj;
 */
export function dateCheckPagamentoEntrada(date: string) : boolean{
  
  if(date){
    const now = moment();
    const currDate = moment(date);
    return currDate.isBefore(now,"days") || currDate.format("DD/MM/YYYY") === now.format("DD/MM/YYYY") ? true :  false;
  }

  return true;

}


/**
 * Verifica se o do vencimento do boleto é hj é hj ou antes de hj;
 */
export function dateCheckBoletoVencimento(date: string) : boolean{
  
  if(date){
    const now = moment();
    const currDate = moment(date);
    return currDate.isBefore(now,"days") || currDate.format("DD/MM/YYYY") === now.format("DD/MM/YYYY") ? true :  false;
  }

  return true;

}


export function nameMonth(mes: string) : string{
  return moment(mes).locale('pt-br').format('MMMM');
}



