import React from 'react';
import { Routes, Route, Navigate} from 'react-router-dom';

import Login from '../Pages/Login';
import Logout from '../Pages/Logout';

export default function Rotas({ logado }) {

  React.useEffect(() => {
    document.title = 'OFFBEER - Barbacena';
  }, []);

  return (
    <Routes>
      <Route
        exact
        path="/login"
        element={logado ? <Navigate to="/" /> : <Login />}
      />
      <Route exact path="/logout" element={<Logout />} />
      <Route
        path="*"
        element={<Navigate to={logado ? '/' : 'login'} replace />}
      />
    </Routes>
  );
}
