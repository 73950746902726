import {
  // AxiosError,
  AxiosResponse
} from "axios";

export interface IApiRespondeError{
  code: number,
  message: string,
  success: boolean,
  error: string,
  description: string,
}

export class ApiResponseError extends Error{
  public response: IApiRespondeError
  public code: number;
  public success: boolean;
  public error: string;
  public description: string;

  constructor(response: AxiosResponse) {
    super(response.data.message);
    this.name = "ApiResponseError";
    this.response = response.data;  
    this.message = this.response.message;
    this.code = this.response.code;
    this.description = this.response.description;
    this.error = this.response.error
    this.success = this.response.success
  }
}
