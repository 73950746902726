const getIcon = (icon) => {
	return {
		'Dashboard': 'ic:round-dashboard',
		'Entrada Produtos': 'fluent:production-checkmark-20-filled',
    "Pedidos":"fluent-mdl2:reservation-orders",
    "Pedido":"icon-park-outline:transaction",
    "Movimentações":"fluent-mdl2:activate-orders",
    "Estoque":"vaadin:stock",
    "Empréstimos":"fluent-mdl2:functional-manager-dashboard",
    "Gestão de Equipamentos":"",
    "Kits de Equipamentos":"material-symbols:tools-installation-kit",
    "Equipamentos":"",
    "Tipos Equipamentos":"",
    "Gestão de Produtos":"fluent-mdl2:product-catalog",
    "Produtos":"fluent-mdl2:product-list",
    "Produto":"fluent-mdl2:product",
    "Tipos de Produtos":"fluent-mdl2:product-variant",
    "Clientes":"ic:baseline-business",
    "Cliente":"ic:baseline-business",
    "Usuários":"mdi:users-group",
    "Usuário":"mdi:user",
    "Pagamentos":"material-symbols:attach-money-rounded",
    "Envio":"carbon:delivery-parcel",
    "Entrega/Devolução":"mdi:map-marker",
    "Recebimento":"ic:baseline-attach-money",
    "Relatórios":"tabler:file-report",
    "Fluxo de Caixa":"tabler:report-money",
    "Histórico":"fluent-mdl2:full-history",
    "Plano Contas":"ic:round-pie-chart",
    "Despesas":"fluent:money-off-20-filled",
    "":"",
	}[icon] || 'iconoir:file-not-found'
}


export default getIcon;