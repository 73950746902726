import * as yup from "yup";
import Validate from "./Validate";

export function LoginPathValidate<T>(data: T): Promise<T> {
	const schema = yup
		.string()
		.nullable()
		.min(3, "No mínimo 3 Caracteres")
		.max(30, "No máximo 30 caracteres")
		.required("Usuário é obrigatório");
	const validate = new Validate(schema, data);
	return validate.execSync();
}

export async function PasswdPathValidate<T>(data: T): Promise<T> {
	const schema = yup
		.string()
		.nullable()
		.min(8, "No mínimo 8 caracteres")
		.max(30, "No máximo 30 caracteres")
		.required("Senha é obrigatório");
	const validate = new Validate(schema, data);
	return await validate.exec();
}

export async function AcessoValidate<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
		login: yup
			.string()
			.nullable()
			.min(3, "No mínimo 3 Caracteres")
			.max(30, "No máximo 30 caracteres")
			.required("Usuário é obrigatório"),
		passwd: yup
			.string()
			.nullable()
			.min(8, "No mínimo 8 caracteres")
			.max(30, "No máximo 30 caracteres")
			.required("Senha é obrigatório"),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}
