import * as yup from "yup";
import Validate from "./Validate";

export async function entregaSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
		tipo: yup.number().required(),
		data: yup.date().notRequired(),
		cep: yup
			.string()
			.trim()
			.min(5, "No mínimo 5 caracteres")
			.max(15, "No máximo 15 caracteres")
			.required(),
		estado: yup
			.string()
			.trim()
			.min(2, "No mínimo 2 caracteres")
			.max(2, "No máximo 2 caracteres")
			.notRequired(),
		cidade: yup
			.string()
			.trim()
			.min(5, "No mínimo 5 Caracteres")
			.max(100, "No máximo 100 Caracteres")
			.notRequired(),
		bairro: yup
			.string()
			.trim()
			.min(5, "No mínimo 5 Caracteres")
			.max(100, "No máximo 100 Caracteres")
			.notRequired(),
		logradouro: yup
			.string()
			.trim()
			.min(5, "No mínimo 5 Caracteres")
			.max(150, "No máximo 150 Caracteres")
			.notRequired(),
		numero: yup
			.number()
			.notRequired()
			.min(0, "Número não pode ser menor que zero.")
			.max(99999, "Número muito grande."),
		complemento: yup.string().trim().notRequired(),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}
