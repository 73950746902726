import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


import { DateFormat } from "../../../Utils/dates"

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: 'row',
    width: "100%"
  },
  column: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "flex-start",
    padding: 10,
    width: "50%",
  },
  row: {
    display: "flex",
    alignItems: "center",
    flexDirection: 'row',
    minHeight: "20px"
  },
  titulo: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    fontWeight: "700",
    textAlign: "center",
    marginTop: 5,
  },
  subtitulo: {
    display: "flex",
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: "bold",
    borderBottomWidth: 1,
    marginBottom: 5,
  },
  key: {
    display: "flex",
    alignItems: "center",
    fontSize: 10,
    fontWeight: "bold"
  },
  value: {
    display: "flex",
    alignItems: "center",
    fontSize: 9,
  },
  obs: {
    padding: 10,
    display: "flex",
    flexDirection: 'row',
    width: "100%",
    borderWidth: 1,
    borderColor: '#ccc',
  }
});

export default function Pedido({ pedido }) {
  return (
    <>
      <Text style={styles.titulo}>Pedido</Text>
      <View style={styles.container}>
        <View style={styles.column}>
          {/* _________________ */}
          <Text style={styles.subtitulo}>Pedido</Text>
          <View style={styles.row}>
            <Text style={styles.key}>ID: </Text>
            <Text style={styles.value}># {pedido.id}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Data Pedido: </Text>
            <Text style={styles.value}>{DateFormat(pedido.created_at, "DD/MM/YYYY HH:MM")}</Text>
          </View>
          {/* _________________ */}
          <Text style={styles.subtitulo}>Cliente</Text>
          <View style={styles.row}>
            <Text style={styles.key}>Nome: </Text>
            <Text style={styles.value}>{pedido.cliente.nome_completo || pedido.cliente.nome_fantasia}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Telefone: </Text>
            <Text style={styles.value}>{pedido.cliente.telefone}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>WhatsApp: </Text>
            <Text style={styles.value}>{pedido.cliente.whatsapp}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Email: </Text>
            <Text style={styles.value}>{pedido.cliente.email}</Text>
          </View>
          {/* _________________ */}
          <Text style={styles.subtitulo}>Recebimento</Text>
          <View style={styles.row}>
            <Text style={styles.key}>Pago: </Text>
            <Text style={styles.value}>{pedido.recebimento.pago === 1 ? "Pago" : "Pagamento Pendente"}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Forma de Pagamento: </Text>
            <Text style={styles.value}>{pedido.recebimento.forma_pg}</Text>
          </View>
          {
            pedido.recebimento.forma_pg === 'Boleto' && (
              <>
                <View style={styles.row}>
                  <Text style={styles.key}>Data Vencimento Boleto: </Text>
                  <Text style={styles.value}>{pedido.recebimento.ven_boleto ? DateFormat(pedido.recebimento.ven_boleto, "DD/MM/YYYY") : "-"}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.key}>Número Boleto: </Text>
                  <Text style={styles.value}>{pedido.recebimento.cod_boleto}</Text>
                </View>
              </>
            )
          }
          {pedido.recebimento.pago === 1 && (
            <View style={styles.row}>
              <Text style={styles.key}>Data Pagamento: </Text>
              <Text style={styles.value}>{pedido.recebimento.pago ? DateFormat(pedido.recebimento.data_pagamento, "DD/MM/YYYY") : "-"}</Text>
            </View>
          )}

          {/* <View style={styles.row}>
            <Text style={styles.key}>Valor Parcial: </Text>
            <Text style={styles.value}>R$ 540</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Desconto: </Text>
            <Text style={styles.value}>R$ 0</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Juros: </Text>
            <Text style={styles.value}>R$ 0</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Valor à Receber: </Text>
            <Text style={styles.value}>R$ 540</Text>
          </View> */}
        </View>
        <View style={styles.column}>
          {/* _________________ */}
          <Text style={styles.subtitulo}>Entrega</Text>
          <View style={styles.row}>
            <Text style={styles.key}>Data Entrega: </Text>
            <Text style={styles.value}>{DateFormat(pedido.entrega.data, "DD/MM/YYYY HH:MM")}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Tipo Entrega: </Text>
            <Text style={styles.value}>{pedido.entrega.tipo === '1' ? "Entrega no cliente" : "Retirado pelo cliente"}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>CEP: </Text>
            <Text style={styles.value}>{pedido.entrega.cep}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Estado: </Text>
            <Text style={styles.value}>{pedido.entrega.estado}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Cidade: </Text>
            <Text style={styles.value}>{pedido.entrega.cidade}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Bairro: </Text>
            <Text style={styles.value}>{pedido.entrega.bairro}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Logradouro:</Text>
            <Text style={styles.value}>{pedido.entrega.logradouro}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Número:</Text>
            <Text style={styles.value}>{pedido.entrega.numero}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Complemento: </Text>
            <Text style={styles.value}>{pedido.entrega.complemento}</Text>
          </View>
          {/* _________________ */}
          <Text style={styles.subtitulo}>Devolução</Text>
          <View style={styles.row}>
            <Text style={styles.key}>Data Devolução: </Text>
            <Text style={styles.value}>{DateFormat(pedido.devolucao.data, "DD/MM/YYYY HH:MM")}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Tipo Devolução: </Text>
            <Text style={styles.value}>{pedido.devolucao.tipo === '1' ? "Retirar no Cliente" : "Cliente devolverá"}</Text>
          </View>
        </View>
      </View>
      {pedido.obs && (
        <View style={styles.container}>
          <View style={styles.row}>
            <View style={styles.obs}>
              <Text style={styles.key}>Observações: </Text>
              <Text style={styles.value}>{pedido.obs}</Text>
            </View>
          </View>
        </View>
      )}

    </>
  )
}  
