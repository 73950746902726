import { BaseAxios } from "./Axios";
import {
	AxiosRequestConfig,
	AxiosResponse,
	AxiosError,
	AxiosInstance,
} from "axios";
import { ApiResponseError } from "../Classes/ApiResponseError";
import BaseApi from "src/Classes/Api.classes";

const AUTH_INVALID = ['invalid token', 'Token não encontrado.','Token não é mais valido.'];

export interface IResponseSuccess<T> {
  status: number,
  success: boolean,
  data: T
}

export class Api<T> extends BaseApi<T>{
	private token: string | null;
	protected api: BaseAxios;
	protected axios: AxiosInstance;

	constructor() {
		super();
		this.token = window.localStorage.getItem("token");
		this.api = new BaseAxios();
		this.axios = this.api.axios;

		// this set this.token;
		this.axios.interceptors.request.use(
			(param) => {

					return {
						...param,
						headers: {
							...param.headers,
							"x-access-token": this.token,
						},
						defaults: {
							headers: {
								...param.headers,
								"x-access-token": this.token,
							},
						},
					};


			},
			(error) => {
				// handling error
				console.log(error);
			}
		);

		// Middleware response backend;
		this.axios.interceptors.response.use(
			(param) => this.success(param),
			(error) => {
				this.error(error);
			}
		);
	}

	protected getToken(): string {
		return `Bearer ${this.token}`;
	}

	protected setToken(token: string): void {
		this.token = token;
	}

	protected index(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IResponseSuccess<T[]>>> {
		return this.axios.get(url, config);
	}


	protected get(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IResponseSuccess<T>>> {
		return this.axios.get(url, config);
	}

	protected delete<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IResponseSuccess<T>>> {
		return this.axios.delete(url, config);
	}

	protected post(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IResponseSuccess<T>>> {
		return this.axios.post(url, data, config);
	}

	protected put(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IResponseSuccess<T>>> {
		return this.axios.put(url, data, config);
	}

	/**
	 * Generic request.
	 *
	 * @access protected
	 * @template T - `TYPE`: expected object.
	 * @template R - `RESPONSE`: expected object inside a axios response format.
	 * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
	 * @returns {Promise<R>} - HTTP axios response payload.
	 * @memberof Api
	 *
	 * @example
	 * api.request({
	 *   method: "GET|POST|DELETE|PUT|PATCH"
	 *   baseUrl: "http://www.domain.com",
	 *   url: "/api/v1/users",
	 *   headers: {
	 *     "Content-Type": "application/json"
	 *  }
	 * }).then((response: AxiosResponse<User>) => response.data)
	 *
	 */
	protected request<T, R = AxiosResponse<T>>(
		config: AxiosRequestConfig
	): Promise<R> {
		return this.axios.request(config);
	}

	protected success<T>(response: AxiosResponse<T>): T {
		return response.data;
	}

	protected error(error: AxiosError<any>): void {
		if (error.response?.status === 401 && error.response?.data) {
			const message = error.response.data.message;
			
			if (AUTH_INVALID.includes(message)) {
				window.location.href = '/logout';
			}
		}

		if(error.code === "ERR_NETWORK"){
			console.log(error)
			throw new Error("Sem reposta com o servidor, por favor, tente mais tarde.")
		}
		
		if (error.response?.data) {
			throw new ApiResponseError(error.response);
		}
	}

}
