import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material/";
import { tableCellClasses } from '@mui/material/TableCell';

import useTable from "../../Contexts/TableContext";

const VisuallyHidden = styled('span')({
  border: 0,
  clip: "rect(0 0 0 0)",
  height: 1,
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  top: 20,
  width: 1,
});

const MyTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


function EnhancedTableHead({ headCells }) {
  const { order, orderBy, handleRequestSort } = useTable();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <MyTableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => handleRequestSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <VisuallyHidden>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </VisuallyHidden>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </MyTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
};

export default React.memo(EnhancedTableHead);
