import React from "react";
import { TablePagination } from "@mui/material";
import useTable from "../../Contexts/TableContext";

export default React.memo(({rowsFiltered = []}) => {
  const {
    rows,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTable();

  return (
    <React.Fragment>
      <TablePagination
        labelRowsPerPage="Linhas por página:"
        rowsPerPageOptions={[10, 15, 25, 50, 100]}
        component="div"
        count={rowsFiltered && rowsFiltered.length === 0 ? rows.length : rowsFiltered.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
});
