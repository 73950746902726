import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#ccc'
const styles = StyleSheet.create({
    row: {
        display: "flex",
        flexDirection: 'row',
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});


const InvoiceTableRow = ({ items }) => {
    const rows = items.length && items.map(item =>
        <View style={styles.row} key={item.id}>
            <Text style={styles.description}>
                <Text>{item.tipo_nome} - </Text>
                <Text>{item.nome}</Text>
            </Text>
            <Text style={styles.qty}>{item.qnt}</Text>
            <Text style={styles.rate}>R$ {item.valor}</Text>
            <Text style={styles.amount}>R$ {(item.qnt * item.valor).toFixed(2)}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow