import * as React from 'react';
import { Button, CircularProgress } from '@mui/material/';

export default function ButtonLoading({ loading = false, children, ...rest }) {
  return (
    <Button
      variant={loading ? 'outlined' : 'contained'}
      disabled={loading}
      {...rest}
    >
      {loading ? <CircularProgress size={25} /> : children}
    </Button>
  );
}
