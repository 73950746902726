import * as React from "react";
import { Menu, MenuItem, Button, Typography, Box, useMediaQuery, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom'

//* COMPONENTS
import BadgeAvatar from '../Components/Avatar/BadgeAvatar'

//* CONTEXT
import useAuth from '../Contexts/AuthContext';
import useUser from '../Contexts/UserContext';

export default function MenuUser() {
  const navigate = useNavigate();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { handleLogout }= useAuth();
	const [anchorEl, setAnchorEl] = React.useState(false);
  const { nome, roleName, role_id } = useUser();


	const handleMenu = React.useCallback((event) => {
		setAnchorEl(event.currentTarget);
    //eslint-disable-next-line
	},[])

	const handleClose = React.useCallback(() => {
		setAnchorEl(null);
    //eslint-disable-next-line
	},[]);

	return (
    <Box component="div" sx={{
      height: '64px',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%'
    }}>
    <Button
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleMenu}
      color="inherit"
      startIcon={<BadgeAvatar />}
    >
      {
        !mdDown && !smDown && (
          <Box sx={{ disply: 'block'}}>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>{nome}</Typography>
          <Typography component="p" sx={{ textTransform: 'capitalize' }}>{roleName}</Typography>
          </Box>
        )
      }

    </Button>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => navigate("/perfil") }>Meu Perfil</MenuItem>
      {role_id === 1 && <MenuItem onClick={() => navigate("/config") }>Configurações</MenuItem>}
      <Divider />
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  </Box>
	);
}
