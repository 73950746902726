import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	Chip,
	TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

//* COMPONENTES
import EnhancedTableHead from "../../Components/Table/Table.Head";
import EnhancedTableToolbar from "../../Components/Table/Table.ToolBar";
import TablePagination from "../../Components/Table/Table.Pagination";
import sortObject from "../../Utils/sortObject";
import ButtonsGroup from "../../Components/ButtonsGroup/Buttons";
// import CircularProcess from '../../Components/Loading';
// import Gravatar from '../../Components/Box/Gravatar';

//* CONTEXT
import useEquipamentos from "../../Contexts/EquipamentosContext";
import useTable from "../../Contexts/TableContext";

// Header Table
const headCells = [
	{
		id: "cod",
		numeric: true,
		disablePadding: false,
		label: "Código",
		sort: true,
	},
	{
		id: "tipo_nome",
		numeric: false,
		disablePadding: false,
		label: "Tipo Equipamento",
		sort: true,
	},
	{
		id: "modelo",
		numeric: false,
		disablePadding: false,
		label: "Modelo",
		align: "center",
		sort: true,
	},
	{
		id: "Nome",
		numeric: false,
		disablePadding: false,
		label: "Nome Equipamento",
		sort: true,
	},
	{
		id: "unitario",
		numeric: false,
		disablePadding: false,
		label: "Unitário",
		align: "center",
		sort: false,
	},
	{
		id: "qnt",
		numeric: false,
		disablePadding: false,
		label: "Qnt. (Disponível)",
		align: "center",
		sort: false,
	},
	{
		id: "qnt_emprestada",
		numeric: false,
		disablePadding: false,
		label: "Qnt. Emprestada",
		align: "center",
		sort: false,
	},
	{
		id: "actions",
		numeric: false,
		disablePadding: false,
		label: "Ações",
		sort: false,
		align: "center",
	},
];

const MyTable = styled(Table)({
	minWidth: 750,
});

const MyTableCell = styled(TableCell)({
	padding: "10px 15px",
});

export default function ProdutosTable() {
	const { equipamentos, getEquipamentos } = useEquipamentos();
	const navigate = useNavigate();
	const {
		page,
		rows,
		setRows,
		rowsPerPage,
		emptyRows,
		order,
		orderBy,
	} = useTable();

	useEffect(() => {
		let render = true;
		(async () => {
			if (render) {
				await getEquipamentos();
			}
		})();

		return function cleanup() {
			render = false;
		};
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (equipamentos && equipamentos.length > 0) {
			return setRows(equipamentos);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [equipamentos]);


	return (
		<React.Fragment>
			<EnhancedTableToolbar
				title="Equipamentos"
				data={equipamentos && equipamentos.length > 0 ? true : false}
				addButton="equipamentos"
			></EnhancedTableToolbar>
			<TableContainer>
				<MyTable
					aria-labelledby="tableTitle"
					size="medium"
					aria-label="enhanced table"
				>
					<EnhancedTableHead headCells={headCells} />
					<TableBody>
						{!equipamentos || equipamentos.length === 0 ? (
							// <CircularProcess type="Table" />
							<TableRow hover tabIndex={-1}></TableRow>
						) : (
							sortObject(rows, order, orderBy, page, rowsPerPage).map(
								(row, index) => {
									return (
										<TableRow hover tabIndex={-1} key={row.id}>
											<MyTableCell align="left">{row.cod}</MyTableCell>
											<MyTableCell align="left">
												<Chip
													variant="outlined"
													color="secondary"
													label={row.tipo_nome}
													onClick={() => navigate(`/tipos/equipamentos/edit/${row.tipo_id}`)}
												/>
											</MyTableCell>
											<MyTableCell align="center">
												{row.modelo}
											</MyTableCell>
											<MyTableCell component="th" scope="row" padding="normal">
												{row.nome}
											</MyTableCell>
											<MyTableCell align="center">
												{row.unitario}
											</MyTableCell>
											<MyTableCell align="center">
											{row.qnt}({row.qnt_disponiveis})
											</MyTableCell>
											<MyTableCell align="center">
											{row.qnt_emprestados || 0}
											</MyTableCell>
											<MyTableCell align="center">
												<ButtonsGroup
													id={row.id}
													path="equipamentos"
													edit
													// remove
													view
												/>
											</MyTableCell>
										</TableRow>
									);
								}
							)
						)}
						{equipamentos && equipamentos.length > 0 && emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
				</MyTable>
			</TableContainer>
			<TablePagination />
		</React.Fragment>
	);
}
