import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo
} from "react";
import PropTypes from "prop-types";
import { redirect } from "react-router-dom";

import useLocalStore from "../Hooks/useLocalStore";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const { getData, removeData } = useLocalStore();
  const [token, setToken] = useState(getData("token") || null);
  const [errors, setErrors] = useState({});
  const logado = useMemo(() => Boolean(token),[token]);

  const handleLogout = useCallback(() => {
    setToken(null);
    removeData('token');
    removeData('user');
    return redirect("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // AUTENTICA AÇÕES COM MUDANÇA DE PÁGINA;
  const handleAuth = useCallback(() => {
    if (!getData("token")) {
      setErrors({
        success: false,
        message: 'Autenticação não encontrada, você foi deslogado do sistema!'
      });
      setToken(null);
      removeData('token');
      removeData('user');
      return redirect("/login");
    }
    return;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <AuthContext.Provider
      value={{
        logado,
        errors,
        token,
        setToken,
        setErrors,
        handleLogout,
        handleAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useAuth() {
  return useContext(AuthContext);
}

export { AuthContext, AuthProvider };
