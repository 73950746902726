import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'black'
const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        height: 20,
        textAlign: 'center',
        fontStyle: 'bold',
        padding: 10,
        flexGrow: 1,
        fontSize: 12
    },
    description: {
        display: "flex",
        alignItems: 'center',
        width: '48%',
        borderTopColor: borderColor,
        borderTopWidth: 1,
    },
    divider: {
        width: '4%',
    },
    containerText: {
        padding: "10px 10px 0 10px",
    },
    text: {
        display: "flex",
        flexDirection: 'row',
        fontSize: 8,
        fontStyle: "italic"
    }
});

const Assinatura = () => (
    <>
        <View style={styles.containerText}>
            <Text style={styles.text}>Assinado esse documento, fico ciente e assumo total responsabilidade, de que: </Text>
            <Text style={styles.text}> - Caso ocorra a perda por roubo ou furto ou dano ou inutilização por emprego inadequado dos equipamentos ou produtos listado, poderá ocorrer cobranças baseado no valor do equipamento ou produto.</Text>
        </View>
        <View style={styles.container}>
            <Text style={styles.description}>Nome Legivél</Text>
            <Text style={styles.divider} />
            <Text style={styles.description}>Assinatura</Text>
        </View>
    </>
);

export default Assinatura