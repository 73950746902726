import React, { createContext, useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const SnackBarContext = createContext({});

const SnackBarProvider = ({ children }) => {
  const navigate = useNavigate();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState(null);
  const [progress, setProgress] = React.useState(0);

  const handleClose = useCallback(() => {
    setOpenSnackBar(!openSnackBar);
    setMessage(null);
    setProgress(0);
  }, [openSnackBar]);

  const handleSnackBar = useCallback(
    ({ type, message: msg }) => {
      if (type === 'error') setMessage(handleError(msg));
      else if (msg && msg.message) setMessage(msg.message);
      else setMessage(msg);

      setSeverity(type);
      setOpenSnackBar(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  
  const navigateSnackBar = useCallback(
    (path, { type, message, error }) => {
      navigate(path, { 
        replace: true, 
        state: {
          type,
          message: handleError(error) || message,
      }});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleError = useCallback(
    (msg) => {

      if(typeof msg === 'string'){
        if (msg === 'Network Error') {
          return 'Erro em se conectar com o servidor!';
        }
        return msg;
      }


      if(typeof msg === "object"){
        if(msg && msg.message){
          return msg.message
        }
      }

      return null

    },
    // eslint-disable-next-line
    []
  );


  return (
    <SnackBarContext.Provider
      value={{
        openSnackBar,
        setOpenSnackBar,
        severity,
        setSeverity,
        message,
        setMessage,
        handleClose,
        handleSnackBar,
        navigateSnackBar,
        progress,
        setProgress
      }}
    >
      {children}
    </SnackBarContext.Provider>
  );
};

SnackBarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useSnackBar() {
  return useContext(SnackBarContext);
}

export { SnackBarContext, SnackBarProvider };
