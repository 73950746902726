import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

export default function Alerta({ severity = "success", title, children, sx }) {
  return (
    <Stack sx={{ width: '100%', mb: 2, ...sx }} spacing={2}>
      <Alert severity={severity}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
      </Alert>
    </Stack>
  );
}