import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#ccc'
const styles = StyleSheet.create({
    row: {
        display: "flex",
        flexDirection: 'row',
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        fontStyle: 'bold',
    },
    description: {
        width: '90%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        textAlign: 'center',
        paddingRight: 8,
    },
});


const InvoiceTableRow = ({ items }) => {

    const rows = items && items.length > 0 && items.map(item =>
        <View style={styles.row} key={item.id}>
            <Text style={styles.description}>
                {item.nome}
            </Text>
            <Text style={styles.qty}>{item.qnt}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow