import * as yup from "yup";
import Validate from "./Validate";

export async function editKitEquipamentoSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
		id: yup.number().required(),
		nome: yup.string().trim().min(5, "No mínimo 5 Caracteres").max(150, "No máximo 150 Caracteres").required("Nome é obrigatório"),
		equipamentos: yup.array(yup.object().shape({
			id: yup.number().integer().notRequired(),
			equipamento_id: yup.number().required(),
			cod: yup.string().min(1, 'Código deve maior que 0.').max(999999, 'Código deve ser menor que 99999.').required("Requerido."),
			qnt: yup.number().default(1),
		}))
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}

export async function newKitEquipamentoSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
		nome: yup.string().trim().min(5, "No mínimo 5 Caracteres").max(150, "No máximo 150 Caracteres").required("Nome é obrigatório"),
		equipamentos: yup.array(yup.object().shape({
			id: yup.number().integer().notRequired(),
			equipamento_id: yup.number().required(),
			cod: yup.string().min(1, 'Código deve maior que 0.').max(999999, 'Código deve ser menor que 99999.').required("Requerido."),
			qnt: yup.number().default(1),
		}))
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}
