import React, {
  createContext,
  useState,
  useContext,
  useCallback,
} from "react";
import PropTypes from "prop-types";

//** CONTEXT
import useSnackBar from './SnackBarContext';
import useLoading from './LoadingContext';

//* SERVICE
import { equipamentosService } from '../Services/Equipamentos.service';

const EquipamentosContext = createContext({});

const EquipamentosProvider = ({ children }) => {
  const { handleSnackBar, navigateSnackBar } = useSnackBar();
  const { setLoadingGet, setLoadingSubmit } = useLoading();
  const [equipamentos, setEquipamentos ] = useState([]);
  const [equipamento, setEquipamento] = React.useState({
    unitario: true,
    qnt: 1
  });
  const [errors, setErrors ] = useState({});


  const getEquipamentos = useCallback(async () => {
    try {
      const Dados = await equipamentosService.getEquipamentos();

      if (Dados.success){
        console.log(Dados.data);
        return setEquipamentos(Dados.data);
      }

      throw Dados.data;
    } catch (error) {
      console.log(error);
      handleSnackBar({
        type: 'error',
        message:
          error && error.message
            ? error.message
            : 'Erro em carregar equipamentos, por favor tente mais tarde.'
      });
    } finally {
      setLoadingGet(false);
    }
    //eslint-disable-next-line
  },[]);


  const getEquipamento = useCallback(async (id) => {
    try {
      const Dados = await equipamentosService.getEquipamento(parseInt(id));

      if (Dados.success){
        return setEquipamento(Dados.data);
      }

      throw Dados.data;
    } catch (error) {
      console.log(error);
      navigateSnackBar("/equipamentos", {
        type: 'error',
        message:
          error && error.message
            ? error.message
            : 'Erro em carregar equipamento, por favor tente mais tarde.'
      })
    } finally {
      setLoadingGet(false);
    }
    //eslint-disable-next-line
  },[])

  const handleSubmitEdit = useCallback(async () => {
  
    try {
      setLoadingSubmit(true);
      const Dados = await equipamentosService.editEquipamento(equipamento);

      if (Dados.success){
        handleSnackBar({
          type: 'success',
          message: 'Usuário atualizado com sucesso.'
        });
        setErrors({})
        return setEquipamento(Dados.data);
      }

      throw Dados.data.message;
    } catch (error) {

      if(!error.isValid && error.errors){
        setErrors(error.errors)
      }

      handleSnackBar({
        type: 'error',
        message:
          error && error.message
            ? error.message
            : 'Erro em atualizar o equipamento.'
      });
    } finally {
      setLoadingSubmit(false);
    }
    //eslint-disable-next-line
  },[equipamento])


  const handleSubmitNew = useCallback(async () => {
    try {
      setLoadingGet(true);
      const Dados = await equipamentosService.newEquipamento(equipamento);

      if (Dados.success){
        navigateSnackBar("/equipamentos", {
          type: 'success',
          message: 'Equipamentos cadastrado com sucesso.'
        })
      }

      throw Dados.data;
    } catch (error) {
      console.log(error);
      if(!error.isValid && error.errors){
        setErrors(error.errors)
      }else{
        handleSnackBar({
          type: 'error',
          message:
            error
              ? error
              : 'Erro em cadastrado o equipamento.'
        });
      }

    } finally {
      setLoadingGet(false);
    }
    //eslint-disable-next-line
  },[equipamento])

  

  return (
    <EquipamentosContext.Provider
      value={{
        equipamentos,
        equipamento, 
        setEquipamento,
        errors,
        setErrors,
        getEquipamentos,
        getEquipamento,
        handleSubmitNew,
        handleSubmitEdit
      }}
    >
      {children}
    </EquipamentosContext.Provider>
  );
};

EquipamentosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useEquipamentos() {
  return useContext(EquipamentosContext);
}

export { EquipamentosContext, EquipamentosProvider };

