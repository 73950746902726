import { useCallback } from "react";
import encryptStore from "src/Utils/encryptStore";

export default function useLocalStore() {
    const getData = useCallback((item: string) => {
        if(item){
            const value = localStorage.getItem(item)
            return value ? JSON.parse(value) : "";
        }
        return "";
    }, []);

    const setData = useCallback((item: string, Dados:any) => {
        if (Dados) localStorage.setItem(item, JSON.stringify(Dados));
    }, []);

    const getDataEncrypt = useCallback((item: string) => {
        return encryptStore.getItem(item);
    }, []);

    const setDataEncrypt = useCallback((item: string, Dados: any) => {
        if (Dados) encryptStore.setItem(item, Dados);
    }, []);

    const removeData = useCallback((item: string) => {
        localStorage.removeItem(item);
    }, []);

    return {
        getData,
        getDataEncrypt,
        setData,
        setDataEncrypt,
        removeData,
    };
};