import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "../../Contexts/AuthContext";

const Logout = () => {
  const { handleLogout } = useAuth();
  let location = useLocation();

  React.useEffect(() => {
    handleLogout();
    /* eslint-disable react-hooks/exhaustive-deps */
  },[])

  return <Navigate to="/login" state={{ from: location }} replace />;
}


export default Logout;