import * as React from "react";
import { useLocation } from "react-router-dom";
import {Stack, Snackbar, Slide, Box} from "@mui/material/";
import MuiAlert from "@mui/material/Alert";
// import LinearProgress from "@mui/material/LinearProgress";

import useSnackBar from "../Contexts/SnackBarContext";

function SlideTransition(props) {
	return <Slide {...props} direction="up" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {
	const location = useLocation();
	const {
		severity,
		message,
		openSnackBar,
		handleClose,
		handleSnackBar,
		// progress,
		// setProgress,
	} = useSnackBar();

	React.useEffect(() => {
		const state = location.state;
		if(state && state.type && state.message){
			handleSnackBar(state);
			window.history.replaceState({}, document.title)
		}
	// eslint-disable-next-line
	},[location])

	// React.useEffect(() => {
	// 	if (openSnackBar) {
	// 		const timer = setInterval(() => {
	// 			setProgress((prevProgress) => prevProgress < 100 ? prevProgress + 5 : clearInterval(timer));
	// 		},225);
	// 		return () => {
	// 			clearInterval(timer);
	// 		};
	// 	}
	// 	// eslint-disable-next-line
	// }, [handleClose, openSnackBar]);

	return (
		<React.Fragment>
			{severity && message && openSnackBar && (
				<Stack spacing={2} sx={{ width: "100%" }}>
					<Snackbar
						anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
						open={openSnackBar}
						autoHideDuration={5000}
						TransitionComponent={SlideTransition}
						onClose={handleClose}
					>
						<Box>
							{/* <LinearProgress
								value={progress}
								variant="determinate"
								color={severity}
								sx={{
									position: "absolute",
									width: "100%",
									borderRadius: 10
								}}
							/> */}
							<Alert
								onClose={handleClose}
								severity={severity ? severity : "success"}
							>
								{message}
							</Alert>
						</Box>
					</Snackbar>
				</Stack>
			)}
		</React.Fragment>
	);
}
