import React, { createContext, useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { uniquesObjects } from '../Utils/functions'

const TableContext = createContext({});

const TableProvider = ({ children }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchKeys, setSearchKeys] = useState([]);
  const [search, setSearch] = useState('');
  

  const handleRequestSort = useCallback(
    (property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy]
  );

  /**
   * Muda para uma nova página
   */
  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
    /* Altera a quantidade de itens por página
     */
  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyRows = React.useMemo(() => {
    return rows && rows.length
      ? rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows, rowsPerPage]);


  const handleSearch = useCallback(
    () => {
      if (search) {
        let results = [];
        const s = search.trim().toLowerCase();
        searchKeys.forEach((key) => {
          const result = rows.filter((Dado) => Dado[key] && Dado[key].toLowerCase().includes(s) && Dado)
          results = [...results, ...result];
        });
        return results;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchKeys,rows, search]
  );

  const handleSearchValue = useCallback(
    (keys, Dados, value) => {
      if (value) {
        let results = [];
        keys.forEach((key) => {
          const result = Dados.filter(
            (Dado) => Dado[key] && Dado[key].toLowerCase().includes(value.toLowerCase()) && Dado
          );
          results = [...results, ...result];
        });
        return uniquesObjects(results);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  
  const rowsFiltered = search.length > 0 ? handleSearch() : rows;
  return (
    <TableContext.Provider
      value={{
        order,
        setOrder,
        orderBy,
        setOrderBy,
        handleRequestSort,
        rows,
        rowsFiltered,
        setRows,
        emptyRows,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        search, 
        setSearch,
        searchKeys, 
        setSearchKeys,
        handleSearchValue,
        handleSearch,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

TableProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useTable() {
  return useContext(TableContext);
}

export { TableContext, TableProvider };
