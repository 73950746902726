import * as React from "react";
// import { Link } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Tootip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SkipNextIcon from '@mui/icons-material/SkipNext';

export default function DisableElevation({ id, path, edit, view, remove, nextStatusPedido, finalizaEmprestimo }) {
	return (
		<ButtonGroup
			disableElevation
			size="small"
			variant="contained"
			aria-label="outlined primary button group"
			sx={{
				"& .MuiButtonBase-root": {
					border: "transparent" 
				}
			}}
		>
			{edit && (
				<Button
					aria-label="editar"
					size="small"
					sx={{ backgroundColor: "#16ab39", border: "transparent" }}
					href={`/${path}/edit/${id}`}
				>
					<Tootip title="Editar">
						<EditIcon fontSize="small" sx={{ color: "white" }} />
					</Tootip>
				</Button>
			)}
			{view && (
				<Button
					aria-label="editar"
					sx={{ backgroundColor: "#2185d0", border: "transparent" }}
					size="small"
					href={`/${path}/view/${id}`}
				>
					<Tootip title="Visualizar">
						<VisibilityIcon fontSize="small" sx={{ color: "white" }} />
					</Tootip>
				</Button>
			)}
			{nextStatusPedido && (
				<Button
					aria-label="next_status"
					size="small"
					sx={{ backgroundColor: "#2c3e50", border: "transparent" }}
					onClick={() => nextStatusPedido(id)}
				>
					<Tootip title="Próximo Status">
					<SkipNextIcon fontSize="small" sx={{ color: "white" }} />
					</Tootip>
				</Button>
			)}
			{finalizaEmprestimo && (
				<Button
					aria-label="finaliza_emprestimo"
					size="small"
					sx={{ backgroundColor: "#2c3e50", border: "transparent" }}
					onClick={() => finalizaEmprestimo(id)}
				>
					<Tootip title="Finaliza Empréstimo">
					<SkipNextIcon fontSize="small" sx={{ color: "white" }} />
					</Tootip>
				</Button>
			)}
			{remove && (
				<Button
					aria-label="editar"
					size="small"
					sx={{ backgroundColor: "#d01919", border: "transparent" }}
					href={`/${path}/remove/${id}`}
				>
					<Tootip title="Remover">
					<DeleteIcon fontSize="small" sx={{ color: "white" }} />
					</Tootip>
				</Button>
			)}
		</ButtonGroup>
	);
}
