import React, {
	createContext,
	useState,
	useContext,
	useCallback,
} from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

//** CONTEXT
import useSnackBar from "./SnackBarContext";
import useLoading from "./LoadingContext";

//* SERVICE
import { pedidosServices } from "../Services/Pedidos.service";

// VALIDATION
import { entregaSchema } from "../Utils/validations/Entrega.schema"

//* HOOKS
import useStatusPedidos from '../Hooks/useStatusPedidos'

const PedidosContext = createContext({});

const PedidosProvider = ({ children }) => {
	const [searchParams] = useSearchParams();
	const { handleSnackBar, navigateSnackBar } = useSnackBar();
	const { setLoadingSubmit, setLoadingGet } = useLoading();
	const [rowsProdutos, setRowsProdutos] = React.useState([]); // Produtos
	const [rowsRecebimentos, setRowsRecebimentos] = React.useState([]); // Recebimentos
	const [indexProdutos, setIndexProdutos] = React.useState(1); // Produtos
	const [indexRecebimentos, setIndexRecebimentos] = React.useState(1); // Recebimentos
	const [pedidos, setPedidos] = useState([]);
	const [pedido, setPedido] = React.useState({
		produtos: [],
		recebimento: {
			valor: 0,
			desconto: 0,
			juros: 0
		},
		cliente: {},
		entrega: {},
		devolucao: {},
		historico: []
	});
	// eslint-disable-next-line
	const [errors, setErrors] = useState({});
	const [atualEndereco, setAtualEndereco] = useState({});
	const [ultimoEndereco, setUltimoEndereco] = useState({});
	const [novoEndereco, setNovoEndereco] = useState({});
	const [filtros, setFiltros] = React.useState(searchParams.get('status') ? {
		status: searchParams.get('status'),
	} : {});
	const [invoces, setInvoces] = React.useState([]);

	const getPedidosByFiltros = useCallback(async () => {
		try {
			setLoadingGet(true);
			
			const [ Pedidos, Invoces ] = await Promise.all([
				await pedidosServices.getPedidosByFiltros(filtros),
				await pedidosServices.getStatusValores(filtros),
			]);


			if (Pedidos.success && Invoces.success) {
				setPedidos(Pedidos.data);
				setInvoces(Invoces.data);
				return Pedidos.data;
			}

			throw Pedidos.data.message || Invoces.data.message;
		} catch (error) {
			console.log(error);
			handleSnackBar({
				type: 'error',
				message:
					error && error.message
						? error.message
						: 'Erro em carregar pedidos filtrados, por favor tente mais tarde.'
			});
		} finally {
			setLoadingGet(false);
		}
		//eslint-disable-next-line
	}, [filtros]);

	const getPedidos = useCallback(async () => {
		try {
			setLoadingGet(true);

			
			const [Pedidos, Invoces] = await Promise.all([
				pedidosServices.getPedidos(),
				pedidosServices.getStatusValores(),
			])

			if (Pedidos.success && Invoces.success) {
				setPedidos(Pedidos.data);
				setInvoces(Invoces.data);
				return Pedidos.data;
			}

			throw Pedidos.data.message || Invoces.data.message;
		} catch (error) {
			console.log(error);
			handleSnackBar({
				type: 'error',
				message:
					error && error.message
						? error.message
						: 'Erro em carregar pedidos, por favor tente mais tarde.'
			});
		} finally {
			setLoadingGet(false);
		}
		//eslint-disable-next-line
	}, []);

	const getPedido = useCallback(async (id) => {
		try {
			setLoadingGet(true);
			const Dados = await pedidosServices.getPedido(parseInt(id));

			if (Dados.success) {
				console.log(Dados.data);
				return setPedido(Dados.data);
			}

			throw Dados.data;
		} catch (error) {
			console.log(error);
			navigateSnackBar("/pedidos", {
				type: 'error',
				error,
				message: "Erro em carregar pedido, por favor tente mais tarde."
			})
		} finally {
			setLoadingGet(false);
		}
		//eslint-disable-next-line
	}, [pedido])


	const handleSubmitUpdate = useCallback(async () => {
		try {

			const Dados = await pedidosServices.editPedido(pedido);

			if (Dados.success) {
				setErrors({});
				setPedido({
					...Dados.data,
					produtos: Dados.data.produtos.map((e, i) => {
						return {
							...e,
							index: i + 1,
						};
					})
				});
				navigateSnackBar("/pedidos", {
					type: 'success',
					message: 'Pedido atualizado com  sucesso.'
				});
			}

			throw Dados.data;
		} catch (error) {
			console.log(error);
			handleSnackBar({
				type: 'error',
				message:
					error
						? error
						: 'Erro atualizar pedido, verifique as informações.'
			});
		} finally {
			setLoadingSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pedido])

	const handleSubmitNew = useCallback(async () => {
		try {
			const entrega = pedido.entrega.tipo === "1" ? await entregaSchema(handleEntrega()) : handleEntrega();

			const Dados = await pedidosServices.newPedido(pedido, entrega);

			if (Dados.success) {
				setErrors({});
				navigateSnackBar("/pedidos", {
					type: 'success',
					message: 'Pedido registrado com  sucesso.'
				});
			}

			throw Dados.data;
		} catch (error) {
			console.log(error);
			handleSnackBar({
				type: 'error',
				message:
					error
						? error
						: 'Erro registrar pedido, verifique as informações.'
			});
		} finally {
			setLoadingSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pedido]);

	// Trata os dados de entrega antes de fazer a action;
	const handleEntrega = useCallback(() => {
		// Entrega no cliente
		if (pedido.entrega.tipo === "1") {
			switch (pedido.endereco) {
				case "atual":
					return {
						...atualEndereco,
						tipo: pedido.entrega.tipo,
						data: pedido.entrega.data || null,
					};

				case "ultimo":
					return {
						...ultimoEndereco,
						tipo: pedido.entrega.tipo,
						data: pedido.entrega.data || null,
					};

				case "novo":
					return {
						...novoEndereco,
						tipo: pedido.entrega.tipo,
						data: pedido.entrega.data || null,
					};

				default:
					break;
			}
		}

		return {
			tipo: pedido.entrega.tipo,
			data: pedido.entrega.data || null,
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pedido.entrega, pedido.endereco, atualEndereco, novoEndereco, ultimoEndereco]);

	const handleNextStatus = useCallback(async (id) => {
		try {

			if (window.confirm(`Deseja atualizar pedido para próximo status?`) === true) {
				setLoadingSubmit(true);
				const Dados = await pedidosServices.nextStatusPedido(id);

				if (Dados.success) {
					setPedidos(pedidos => pedidos.map(pedido => {
						if (pedido.id === id) {
							return {
								...pedido,
								status: Dados.data.single
							}
						}
						return pedido;
					}))
					setPedido(pedido => pedido ? {
						...pedido,
						status: Dados.data.single
					} : pedido)
					return handleSnackBar({
						type: 'success',
						message: 'Status alterado com sucesso.'
					});
				}

				throw Dados.data;
			} else {
				return;
			}


		} catch (error) {
			console.log(error);
			handleSnackBar({
				type: 'error',
				message:
					error
						? error
						: 'Erro em alterar para o próximo status.'
			});
		} finally {
			setLoadingSubmit(false);
		}
		//eslint-disable-next-line
	}, [])

	const removeProdutoList = useCallback((index) => {
		setPedido((e) => {
			return {
				...e,
				produtos: e.produtos.filter((produto) => produto.index !== index),
			}

		});
		setRowsProdutos((e) => {
			return e.filter((row) => row.props.index !== index);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClickChangeStatus = React.useCallback(async (status) => {
		try {

			// no-restricted-globals
			if (window.confirm(`Deseja atualizar o pedido para, ${status} ?`) === true) {
				const Dados = await pedidosServices.editPedidoStatus({
					status,
					id: pedido.id
				});

				if (Dados.success) {
					handleSnackBar({
						type: 'success',
						message: 'Status do pedido atualizado com sucesso.'
					});
					return setPedido(pedido => ({ ...pedido, status }));
				}

				throw Dados.data;
			} else {
				return;
			}

		} catch (error) {
			console.log(error);
			handleSnackBar({
				type: 'error',
				message:
					error && error.message
						? error.message
						: 'Erro atualizar pedido, verifique as informações.'
			});
		} finally {
			setLoadingSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pedido.id])

	return (
		<PedidosContext.Provider
			value={{
				pedido,
				setPedido,
				pedidos,
				setPedidos,
				rowsProdutos,
				setRowsProdutos,
				rowsRecebimentos,
				setRowsRecebimentos,
				indexProdutos,
				setIndexProdutos,
				indexRecebimentos,
				setIndexRecebimentos,
				atualEndereco,
				setAtualEndereco,
				ultimoEndereco,
				setUltimoEndereco,
				novoEndereco,
				setNovoEndereco,
				filtros,
				setFiltros,
				invoces,
				handleSubmitNew,
				handleSubmitUpdate,
				handleNextStatus,
				getPedidos,
				getPedidosByFiltros,
				getPedido,
				removeProdutoList,
				onClickChangeStatus,
				useStatusPedidos,
			}}
		>
			{children}
		</PedidosContext.Provider>
	);
};

PedidosProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default function useClientes() {
	return useContext(PedidosContext);
}

export { PedidosContext, PedidosProvider };
