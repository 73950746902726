import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


import { DateFormat } from "../../../Utils/dates"

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: 'row',
    width: "100%"
  },
  column: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "flex-start",
    padding: 10,
    width: "50%",
  },
  row: {
    display: "flex",
    alignItems: "center",
    flexDirection: 'row',
    minHeight: "20px"
  },
  titulo: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    textAlign: "center",
    marginTop: 5,
    color: "white"
  },
  subtitulo: {
    display: "flex",
    flexDirection: 'row',
    fontSize: 14,
    borderBottom: "1px solid black",
    borderBottomWidth: 1,
    marginBottom: 5,
  },
  key: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    fontWeight: "bold"
  },
  value: {
    display: "flex",
    alignItems: "center",
    fontSize: 10,
  },
  obs:{
    height: 100
  }
});

export default function Emprestimo({ emprestimo }) {
  return (
    <>
      <Text style={styles.titulo}>.</Text>
      <View style={styles.container}>
        <View style={styles.column}>
          {/* _________________ */}
          <Text style={styles.subtitulo}>Emprestimo</Text>
          <View style={styles.row}>
            <Text style={styles.key}>ID: </Text>
            <Text style={styles.value}># {emprestimo.id}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Data Entrega: </Text>
            <Text style={styles.value}>{DateFormat(emprestimo.data_entrega, "DD/MM/YYYY HH:MM")}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Data Devolução: </Text>
            <Text style={styles.value}>{DateFormat(emprestimo.data_devolucao, "DD/MM/YYYY HH:MM")}</Text>
          </View>
          {/* _________________ */}
          <Text style={styles.subtitulo}>Cliente</Text>
          <View style={styles.row}>
            <Text style={styles.key}>Nome: </Text>
            <Text style={styles.value}>{emprestimo.revendedor.nome_completo || emprestimo.revendedor.nome_fantasia}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>CNPJ/CPF: </Text>
            <Text style={styles.value}>{emprestimo.revendedor.cnpj_cpf}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Telefone: </Text>
            <Text style={styles.value}>{emprestimo.revendedor.telefone}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Email: </Text>
            <Text style={styles.value}>{emprestimo.revendedor.email}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Revendedor: </Text>
            <Text style={styles.value}>Sim</Text>
          </View>
        </View>
        <View style={styles.column}>
          {/* _________________ */}
          <Text style={styles.subtitulo}>Entrega</Text>
          <View style={styles.row}>
            <Text style={styles.key}>CEP: </Text>
            <Text style={styles.value}>{emprestimo.revendedor.cep}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Estado: </Text>
            <Text style={styles.value}>{emprestimo.revendedor.estado}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Cidade: </Text>
            <Text style={styles.value}>{emprestimo.revendedor.cidade}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Bairro: </Text>
            <Text style={styles.value}>{emprestimo.revendedor.bairro}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Logradouro:</Text>
            <Text style={styles.value}>{emprestimo.revendedor.logradouro}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Número:</Text>
            <Text style={styles.value}>{emprestimo.revendedor.numero}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Complemento: </Text>
            <Text style={styles.value}>{emprestimo.revendedor.complemento}</Text>
          </View>
          {/* _________________ */}
        </View>
      </View>
      <View style={styles.obs}>
        <Text style={styles.subtitulo}>Observação</Text>
        <View style={styles.row}>
          <Text style={styles.value}>{emprestimo.obs}</Text>
        </View>
      </View>
    </>
  )
}  
