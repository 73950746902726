import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


import TableHeaderEmprestimos from '../../../Components/PDF/TableHeaderEquipamentos'
import TableRowEquipamentos from '../../../Components/PDF/TableRowEquipamentos'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  titulo: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    textAlign: "center",
    marginTop: 10,
  },
  text: {
    fontSize: 8,
    fontStyle: "italic"
  }
});

export default function Emprestimo({ emprestimo }) {

  return (
    <>
      {emprestimo && emprestimo.id && (
        <>
          <Text style={styles.titulo}>Emprestimos</Text>
          <View style={styles.tableContainer}>
            <TableHeaderEmprestimos />
            <TableRowEquipamentos items={emprestimo && emprestimo.id ? emprestimo.equipamentos : []} />
          </View>
        </>
      )}
    </>
  )
}  
