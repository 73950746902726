import React from 'react';
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';

import PedidoPDF from '../Pages/Pedidos/PDF/Render';
import EmprestimoPDF from '../Pages/Emprestimos/PDF/Render';


//* CONTEXT
import useAuth from '../Contexts/AuthContext';

const LazyPerfil = React.lazy(() => import('../Pages/Menu/Perfil'));

// PEDIDOS
const LazyPedidos = React.lazy(() => import('../Pages/Pedidos'));
const LazyPedidosView = React.lazy(() => import('../Pages/Pedidos/View'));

// EMPRESTIMOS
const LazyEmprestimos = React.lazy(() => import('../Pages/Emprestimos'));
const LazyEmprestimosView = React.lazy(() =>
  import('../Pages/Emprestimos/View')
);



export default function RotasEntregador({ role_id }) {
  return (
    <Routes>
      <Route element={<RequireAuthEntregador role_id={role_id} />}>

        {/** PEDIDOS */}
        <Route path="pedidos">
          <Route index element={<LazyPedidos />} />
          <Route path="view/:id" element={<LazyPedidosView />} />
          <Route path="pdf/:id" element={<PedidoPDF />} />
        </Route>

        {/* EMPRESTIMOS */}
        <Route path="emprestimos">
          <Route index element={<LazyEmprestimos />} />
          <Route path="view/:id" element={<LazyEmprestimosView />} />
          <Route path="pdf/:id" element={<EmprestimoPDF />} />
        </Route>

        <Route path="/perfil" element={<LazyPerfil />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>


    </Routes>
  );
}


function RequireAuthEntregador({ role_id }) {
  let { handleAuth } = useAuth();
  let location = useLocation();

  React.useEffect(() => {
    handleAuth();
    // eslint-disable-next-line
  });

  return role_id === 5 ? (
      <Outlet />
  ) : <Navigate to="/" state={{ from: location }} replace={true} />
}