import React  from "react";
import {
  Document,
  Page,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { useParams } from 'react-router-dom';

//* COMPONENT
import Header from '../../../Components/PDF/Header'
import Assinatura from '../../../Components/PDF/Assinatura'
import ResumoPedido from './Pedido'
import Produtos from './Produtos'
import Emprestimos from './Emprestimos'

//* CONTEXT
import usePedidos from "../../../Contexts/PedidosContext";
import useLoading  from "../../../Contexts/LoadingContext";

//* PROVIDER
import { PedidosProvider } from "../../../Contexts/PedidosContext";
import { LoadingProvider }  from "../../../Contexts/LoadingContext";

// Create styles
const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    padding: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
});


export default function PedidoPDF() {
  return (
    <LoadingProvider>
      <PedidosProvider>
          <RenderPDF />
      </PedidosProvider>
    </LoadingProvider>
  )
}


function RenderPDF(){
  const { id } = useParams();
  const { getPedido, pedido } = usePedidos();
  const { loadingGet } = useLoading();

  React.useEffect(() => {
    let render = true;

    (async () => {
      if (render) {
        await getPedido(id);
      }
    })();

    return function cleanup() {
      render = false;
    };

    //eslint-disable-next-line
  }, []);


  return (
    <PDFViewer style={{ flex: 1, width: "100%", height: "1000px" }}>
      { !loadingGet && pedido && <BasicDocument id={id} pedido={pedido}/> }
    </PDFViewer>
  )

}


// Create Document Component
function BasicDocument({ id, pedido }) {


  return (
    <Document title="Resumo de Pedido" language="pt-BR">
      <Page size="A4" style={styles.page}>
        <Header />
        <ResumoPedido pedido={pedido} />
        { pedido && pedido.produtos && pedido.produtos.length > 0 && <Produtos produtos={pedido.produtos} juros={pedido.recebimento.juros} desconto={pedido.recebimento.desconto}/> }
        { pedido && pedido.emprestimo && <Emprestimos emprestimo={pedido.emprestimo} />}
        <Assinatura />
      </Page>
    </Document>
  );
}