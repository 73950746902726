import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


import TableHeaderEquipamentos from '../../../Components/PDF/TableHeaderEquipamentos'
import TableRowEquipamentos from '../../../Components/PDF/TableRowEquipamentos'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  titulo: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    textAlign: "center",
    marginTop: 10,
  },
  text:{
    fontSize: 8,
    fontStyle: "italic"
  }
});

export default function Emprestimo({ emprestimo }) {

  return (
    <>
      <Text style={styles.titulo}>Equipamentos Emprestados</Text>
      <View style={styles.tableContainer}>
        <TableHeaderEquipamentos />
        <TableRowEquipamentos items={emprestimo && emprestimo.id ? emprestimo.equipamentos : []} />
      </View>
        <Text style={styles.text}>Assinado esse documento, fico ciente e assumo total responsabilidade, de que: </Text>
        <Text style={styles.text}> - Caso ocorra a perda por roubo ou furto ou dano ou inutilização por emprego inadequado dos equipamentos ou produtos listado, poderá ocorrer cobranças baseado no valor do equipamento ou produto.</Text>
    </>

  )
}  
