import { IResponseSuccess } from './Api.service';
import { AxiosResponse } from 'axios';
import { Service } from './Services';

// Interfaces

// API Error;
import { ApiResponseError } from '../Classes/ApiResponseError'

// Validate
import {  newEquipamentoSchema, editEquipamentoSchema } from '../Utils/validations/Equipamentos.schema';

export interface IEquipamento {
    id?: number,
    cod: string,
    nome: string,
    modelo: string,
    unitario: number,
    tipo_id: number,
    imagem?: string,
    active: boolean,
    obs: string,
    created_at?: string,
    updated_at?: string
}

export class EquipamentosService extends Service<IEquipamento>{


  constructor(path: string){
    super(path);
    this.path = path
  }
  

  public async getEquipamentos(): Promise<AxiosResponse<IResponseSuccess<IEquipamento[]>>> {
    try {
      const response = await this.getAll();
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async getEquipamento(id: number): Promise<AxiosResponse<IResponseSuccess<IEquipamento>>> {
    try {
      const response = await this.getOne(id);
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async getEquipamentosDisponiveis(): Promise<AxiosResponse<IResponseSuccess<IEquipamento>>> {
    try {
      const response = await this.get(`${this.path}/find/all/disponiveis`);
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async getEquipamentosUnitariosDisponiveis(): Promise<AxiosResponse<IResponseSuccess<IEquipamento>>> {
    try {
      const response = await this.get(`${this.path}/find/unitarios/disponiveis`);
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async getQntDisponivelByID(id: number): Promise<AxiosResponse<IResponseSuccess<IEquipamento>>> {
    try {
      const response = await this.get(`${this.path}/estoque/${id}`);
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async editEquipamento(Dados: IEquipamento): Promise<AxiosResponse<IResponseSuccess<IEquipamento>>> {
    try {

      const data = await editEquipamentoSchema<IEquipamento>(Dados);

      const response = await this.edit(data);
      return response;
    } catch (error) {
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async newEquipamento(Dados: IEquipamento): Promise<AxiosResponse<IResponseSuccess<IEquipamento>>> {
    try {

      const data = await newEquipamentoSchema<IEquipamento>(Dados);
      const response = await this.new(data);
      return response;
    } catch (error) {
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }



} 



export const equipamentosService = new EquipamentosService('/equipamentos');