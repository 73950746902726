import * as React from "react";
import { Box, useMediaQuery, Toolbar } from "@mui/material";

//* COMPONENTE
import CustomizedSnackbars from "./Snackbar";
import Alerta from '../Components/Alerts/Messagens'

//* HOOKS
import useLocalStore from "src/Hooks/useLocalStore";

export default function Container({ children }) {
	const { getData } = useLocalStore();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));


	return (
			<Box
				component="main"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === "light"
							? theme.palette.grey[100]
							: theme.palette.grey[900],
					flexGrow: 1,
					height: "100vh",
					overflow: "auto",
				}}
			>
				<Toolbar />
				<Box sx={{ m: smDown ? 2 : 4 }}>
					{!getData("cliente") && <Alerta severity="warning"  sx={{ mb: 2 }} spacing={2} title="Atenção">Devido alterações importantes, você deve deslogar e logar novamente para o sistema funcionar corretamente.</Alerta>}
					{children}
				</Box>
				<CustomizedSnackbars />
			</Box>
	);
}
