import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles'

function StatusPedido(status){
  const theme = useTheme();

  const AllStatus = useMemo( () => [
    {
      plural: "Entregas Agendadas",
      single: "Entrega Agendada",
      singleJunto: "EntregaAgendada",
      icon: "mdi:truck-delivery",
      palette: {
        color: theme.palette.status.entregaAgendada,
        background: (opacity) => theme.palette.status.entregaAgendadaBackground(opacity)
      }
    },
    {
      plural: "Entregas Pendentes",
      single: "Entrega Pendente",
      singleJunto: "EntregaPendente",
      icon: "mdi:truck-alert",
      palette: {
        color: theme.palette.status.entregaPedente,
        background: (opacity) => theme.palette.status.entregaPedenteBackground(opacity)
      }
    },
    {
      plural: "Entregues",
      single: "Entregue",
      singleJunto: "Entregue",
      icon: "mdi:truck-check",
      palette: {
        color: theme.palette.status.entregue,
        background: (opacity) => theme.palette.status.entregueBackground(opacity)
      }
    },
    {
      plural: "Devoluções Pendentes",
      single: "Devolução Pendente",
      singleJunto: "DevolucaoPendente",
      icon: "tabler:truck-return",
      palette: {
        color: theme.palette.status.devolucaoPendente,
        background: (opacity) => theme.palette.status.devolucaoPendenteBackground(opacity)
      }
    },
    {
      plural: "Pagamentos Pendentes",
      single: "Pagamento Pendente",
      singleJunto: "PagamentoPendente",
      icon: "material-symbols:paid-outline",
      palette: {
        color: theme.palette.status.pagamentoPedente,
        background: (opacity) => theme.palette.status.pagamentoPedenteBackground(opacity)
      }
    },
    {
      plural: "Pagos",
      single: "Pago",
      singleJunto: "Pago",
      icon: "material-symbols:paid",
      palette: {
        color: theme.palette.status.pago,
        background: (opacity) => theme.palette.status.pagoBackground(opacity)
      }
    },
    {
      plural: "Finalizados",
      single: "Finalizado",
      singleJunto: "Finalizado",
      icon: "mdi:success-circle",
      palette: {
        color: theme.palette.status.finalizado,
        background: (opacity) => theme.palette.status.finalizadoBackground(opacity)
      }
    },
    {
      plural: "Cancelados",
      single: "Cancelado",
      singleJunto: "Cancelado",
      icon: "mdi:close-circle",
      palette: {
        color: theme.palette.status.cancelado,
        background: (opacity) => theme.palette.status.canceladoBackground(opacity)
      }
    },
    // eslint-disable-next-line
  ],[]);


  

  return status ? AllStatus.find(s => s.single === status || s.plural === status) : AllStatus 
}


export default StatusPedido;
