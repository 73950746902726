import React from 'react';
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import RotasAdmin from "./RoutesAdmin"
import RotasVendedor from "./RoutesVendedor"
import RotasEntregador from "./RoutesEntregador"

// TEMPLATES
import Container from '../Templates/Container';


//* CONTEXT
import useAuth from '../Contexts/AuthContext';
import useUser from '../Contexts/UserContext';

// HOME
const LazyHome = React.lazy(() => import('../Pages/Home'));

export default function RotasAuth() {
  const { role_id } = useUser();

  React.useEffect(() => {
    document.title = 'OFF BEER';
  }, []);

  return (
    <Routes>
      <Route path="/" element={<RequireLogado />}>
        <Route index element={<LazyHome />} />
        {role_id === 5 && <Route path="*" element={<RotasEntregador role_id={5} />} />}
        {role_id === 4 && <Route path="*" element={<RotasVendedor role_id={4} />} />}
        {role_id === 1 && <Route path="*" element={<RotasAdmin role_id={1} />} />}
      </Route>
    </Routes>
  );
}

function RequireLogado() {
  let { logado, handleAuth } = useAuth();
  let location = useLocation();

  React.useEffect(() => {
    handleAuth();
    // eslint-disable-next-line
  });

  return logado ? (
    <Container>
      <Outlet />
    </Container>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace={true} />
  );
}
