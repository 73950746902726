import axios, { AxiosInterceptorManager, AxiosInstance, AxiosRequestConfig, AxiosResponse, Axios } from "axios";

export interface Iinterceptors{
  request: AxiosInterceptorManager<AxiosRequestConfig>;
  response: AxiosInterceptorManager<AxiosResponse>;
}

export class BaseAxios extends Axios{
  public axios: AxiosInstance;

  constructor(){
    super();
    this.axios = axios.create({
      baseURL: this.getBaseUrlDomain(),
      timeout: 30000,
      withCredentials: true,
    });
  }

  private getBaseUrlDomain(){
    if(process.env.REACT_APP_BACKEND_URL)
      return process.env.REACT_APP_BACKEND_URL

    if(window.location.origin.indexOf("offbeerbq.com.br") > 0){ 
      return process.env.REACT_APP_BACKEND_API_BQ
    }else if(window.location.origin.indexOf("offbeeres.com.br") > 0){
      return process.env.REACT_APP_BACKEND_API_ES
    }

    return "http://localhost:4000";
  }

}

