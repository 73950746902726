import { IResponseSuccess } from './Api.service';
import { AxiosResponse } from 'axios';
import { Service } from './Services'

// API Error;
import { ApiResponseError } from '../Classes/ApiResponseError'

// Validate
import { editClientePJSchema, editClientePFSchema, newClientePFSchema, newClientePJSchema } from '../Utils/validations/Cliente.schema';

export interface ICliente {
    id: number,
    nome_completo?: string,
    nome_fantasia?: string,
    razao_social?: string,
    cnpj_cpf?: string,
    revendedor: number,
    tipo: string,
    cep: string,
    estado: string,
    cidade: string,
    bairro: string,
    logradouro: string,
    numero: number,
    complemento: string,
    active: boolean,
    user_id: number,
    obs: string,
    created_at?: string,
    updated_at?: string
}

export class ClientesService extends Service<ICliente>{

  constructor(path: string){
    super(path);
    this.path = path
  }

  public async getClientes(): Promise<AxiosResponse<IResponseSuccess<ICliente[]>>> {
    try {
      const response = await this.getAll();
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async getCliente(id: number): Promise<AxiosResponse<IResponseSuccess<ICliente>>> {
    try {
      const response = await this.getOne(id);
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async getRevendedores(): Promise<AxiosResponse<IResponseSuccess<ICliente>>> {
    try {
      const response = await this.get(`/clientes/find/revendedores`);
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async getLastEnderecoPedidoByClienteID(cliente_id: number): Promise<AxiosResponse<IResponseSuccess<ICliente>>> {
    try {
      const response = await this.get(`/clientes/${cliente_id}/entrega/last`);
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }

  public async editCliente(Dados: ICliente): Promise<AxiosResponse<IResponseSuccess<ICliente>>> {
    try {
      const data = Dados.nome_completo ? await editClientePFSchema<ICliente>(Dados) : await editClientePJSchema<ICliente>(Dados)
      const response = await this.edit(data);
      return response;
    } catch (error) {
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }


  public async newCliente(Dados: ICliente): Promise<AxiosResponse<IResponseSuccess<ICliente>>> {
    try {
      const response = await this.new(Dados.nome_completo ? await newClientePFSchema<ICliente>(Dados) : await newClientePJSchema<ICliente>(Dados));
      return response;
    } catch (error) {
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  }


} 



export const clienteService = new ClientesService("clientes");