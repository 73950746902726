import React from "react";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';

//** PROVIDER
import { LoginProvider } from "../../Contexts/LoginContext";

//* Component
import FormLogin from "./FormLogin";

const GridImagem = styled(Grid)(({ theme }) => ({
	height: '100vh',
	backgroundColor: "#ee4422",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
  [theme.breakpoints.down('md')]: {
    display: "none"
  },
}));

const GridFromLogin = styled(Grid)(({ theme }) => ({
	padding: '50px',
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
  [theme.breakpoints.down('md')]: {
    padding: '20px',
  },
}));

export default function Login() {
	return (
		<LoginProvider>
			<Grid container position="fixed">
				<GridFromLogin
					item
					lg={4}
					sm={12}
					sx={{
						padding: '50px',
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
				<FormLogin />
				</GridFromLogin>
				<GridImagem
					item
					xs={8}
				>
					<img src="/static/images/logos/fundo_login.png" loading="lazy" alt="fundo"/>
				</GridImagem>
			</Grid>
		</LoginProvider>
	);
}
