import * as yup from "yup";
import { AnySchema } from "yup";

interface IErrorReturn {
	[key: string]: string;
}

export default class Validate<T> {
	private schema: AnySchema;
	private data: T;

	constructor(schema: AnySchema, data: T) {
		this.schema = schema;
		this.data = data;
	}

	public async exec(): Promise<any> {
		try {
			return await this.schema.validate(this.data, {
				abortEarly: false,
				stripUnknown: true,
			});
		} catch (error) {
			if (error instanceof yup.ValidationError) {
				if (typeof this.data === "object") {
					const errorMessages: IErrorReturn = {};
					error.inner.forEach((error) => {
						if (error.path) errorMessages[error.path] = error.message;
					});
					// eslint-disable-next-line no-throw-literal
					throw {
						isValid: false,
						errors: errorMessages,
					};
				} else {
					// eslint-disable-next-line no-throw-literal
					throw {
						isValid: false,
						errors: error.inner[0].message,
					};
				}
			}
		}
	}

	public execSync(): any {
		try {
			return this.schema.validateSync(this.data, {
				abortEarly: false,
				stripUnknown: true,
			});
		} catch (error) {
			if (error instanceof yup.ValidationError) {
        // eslint-disable-next-line no-throw-literal
        return {
          isValid: false,
          error: error.inner[0].message,
        };
			}
		}
	}
}
