import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';



const borderColor = '#ccc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});


const InvoiceTableFooter = ({ items, juros, desconto }) => {
    const total = items.map(item => item.qnt * item.valor)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    return (
        <>
            <View style={styles.row}>
                <Text style={styles.description}>Juros</Text>
                <Text style={styles.total}>+ R$ {juros || "0.0" }</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>Descontos</Text>
                <Text style={styles.total}>- R$ {desconto || "0.0" }</Text>
            </View>

            <View style={styles.row}>
                <Text style={styles.description}>TOTAL À PAGAR</Text>
                <Text style={styles.total}>R$ {Number.parseFloat((total + juros) - desconto).toFixed(2)}</Text>
            </View>
        </>
    )
};

export default InvoiceTableFooter