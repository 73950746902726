import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo
} from "react";
import PropTypes from "prop-types";
import { redirect } from "react-router-dom";

import useLocalStore from "../Hooks/useLocalStore";

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const { getDataEncrypt, getData, setData } = useLocalStore();
  const { nome, role_id } = useMemo(() => {
    const value = getDataEncrypt('user');
    return value ? value : redirect("/logout");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const roleNames = React.useMemo(() => {
    return ["","Administrador","","","Vendedor","Entregador",""];
  },[])
  const roleName = React.useMemo(() => {
    return roleNames[parseInt(role_id)];
  },[roleNames,role_id])
  const cliente = useMemo(() => getData('cliente'));

  const changeCliente = useCallback((newCliente) => {
    setData("cliente",newCliente);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <UserContext.Provider
      value={{
        nome,
        role_id,
        roleNames,
        roleName,
        cliente,
        changeCliente
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useUser() {
  return useContext(UserContext);
}

export { UserContext, UserProvider };
