import React from 'react';
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';


import PedidoPDF from '../Pages/Pedidos/PDF/Render';
import EmprestimoPDF from '../Pages/Emprestimos/PDF/Render';


// EQUIPAMENTOS
import Equipamentos from '../Pages/Equipamentos';

//* CONTEXT
import useAuth from '../Contexts/AuthContext';


// HOME
const LazyConfigs = React.lazy(() => import('../Pages/Menu/Config'));
const LazyPerfil = React.lazy(() => import('../Pages/Menu/Perfil'));

// USUÁRIOS
const LazyUsuarios = React.lazy(() => import('../Pages/Usuarios'));
const LazyUsuariosEdit = React.lazy(() => import('../Pages/Usuarios/Edit'));
const LazyUsuariosNew = React.lazy(() => import('../Pages/Usuarios/New'));

// CLIENTES
const LazyClientes = React.lazy(() => import('../Pages/Clientes'));
const LazyClientesEdit = React.lazy(() => import('../Pages/Clientes/Edit'));
const LazyClientesNew = React.lazy(() => import('../Pages/Clientes/New'));
const LazyClientesView = React.lazy(() => import('../Pages/Clientes/View'));

// PRODUTOS
const LazyProdutos = React.lazy(() => import('../Pages/Produtos'));
const LazyProdutosEdit = React.lazy(() => import('../Pages/Produtos/Edit'));
const LazyProdutosNew = React.lazy(() => import('../Pages/Produtos/New'));
const LazyProdutosView = React.lazy(() => import('../Pages/Produtos/View'));

// TIPOS PRODUTOS
const LazyTiposProdutos = React.lazy(() => import('../Pages/TiposProdutos'));
const LazyTiposProdutosEdit = React.lazy(() =>
  import('../Pages/TiposProdutos/Edit')
);
const LazyTiposProdutosNew = React.lazy(() =>
  import('../Pages/TiposProdutos/New')
);

// ENTRADAS PRODUTOS
const LazyEntradasProdutos = React.lazy(() =>
  import('../Pages/EntradasProdutos')
);
const LazyEntradasProdutosEdit = React.lazy(() =>
  import('../Pages/EntradasProdutos/Edit')
);
const LazyEntradasProdutosNew = React.lazy(() =>
  import('../Pages/EntradasProdutos/New')
);
const LazyEntradasProdutosView = React.lazy(() =>
  import('../Pages/EntradasProdutos/View')
);

// PEDIDOS
const LazyPedidos = React.lazy(() => import('../Pages/Pedidos'));
const LazyPedidosNew = React.lazy(() => import('../Pages/Pedidos/New'));
const LazyPedidosEdit = React.lazy(() => import('../Pages/Pedidos/Edit'));
const LazyPedidosView = React.lazy(() => import('../Pages/Pedidos/View'));


// TIPOS EQUIPAMENTOS
const LazyTiposEquipamentos = React.lazy(() =>
  import('../Pages/TiposEquipamentos')
);
const LazyTiposEquipamentosNew = React.lazy(() =>
  import('../Pages/TiposEquipamentos/New')
);
const LazyTiposEquipamentosEdit = React.lazy(() =>
  import('../Pages/TiposEquipamentos/Edit')
);

// EQUIPAMENTOS
const LazyEquipamentos = React.lazy(() => import('../Pages/Equipamentos'));
const LazyEquipamentosNew = React.lazy(() =>
  import('../Pages/Equipamentos/New')
);
const LazyEquipamentosEdit = React.lazy(() =>
  import('../Pages/Equipamentos/Edit')
);
const LazyEquipamentosView = React.lazy(() =>
  import('../Pages/Equipamentos/View')
);

// KIT EQUIPAMENTO
const LazyKitsEquipamentos = React.lazy(() =>
  import('../Pages/KitsEquipamentos')
);
const LazyKitsEquipamentosNew = React.lazy(() =>
  import('../Pages/KitsEquipamentos/New')
);
const LazyKitsEquipamentosEdit = React.lazy(() =>
  import('../Pages/KitsEquipamentos/Edit')
);
const LazyKitsEquipamentosView = React.lazy(() =>
  import('../Pages/KitsEquipamentos/View')
);

// EMPRESTIMOS
const LazyEmprestimos = React.lazy(() => import('../Pages/Emprestimos'));
const LazyEmprestimosNew = React.lazy(() => import('../Pages/Emprestimos/New'));
const LazyEmprestimosEdit = React.lazy(() =>
  import('../Pages/Emprestimos/Edit')
);
const LazyEmprestimosView = React.lazy(() =>
  import('../Pages/Emprestimos/View')
);

// PLANO CONTAS
const LazyPlanoContas = React.lazy(() => import('../Pages/PlanoContas'));
const LazyPlanoContasNew = React.lazy(() => import('../Pages/PlanoContas/New'));
const LazyPlanoContasEdit = React.lazy(() => import('../Pages/PlanoContas/Edit'));

// DESPESAS
const LazyDespesas = React.lazy(() => import('../Pages/Despesas'));
const LazyDespesasNew = React.lazy(() => import('../Pages/Despesas/New'));
const LazyDespesasEdit = React.lazy(() => import('../Pages/Despesas/Edit'));
const LazyDespesasView = React.lazy(() => import('../Pages/Despesas/View'));

// RELATORIOS
const LazyRelatorioPedidos = React.lazy(() => import('../Pages/Relatorios/Pedidos'));
const LazyRelatorioFluxoCaixa = React.lazy(() => import('../Pages/Relatorios/FluxoCaixa'));
const LazyRelatorioMovimentacoes = React.lazy(() => import('../Pages/Relatorios/Movimentacoes'));
const LazyRelatorioEstoqueProdutos = React.lazy(() => import('../Pages/Relatorios/Estoque'));


export default function RotasAdmin({ role_id }) {

  return (
    <Routes>
      <Route element={<RequireAuthAdmin role_id={role_id} />}>

        {/* USUÁRIOS */}
        <Route path="usuarios">
          <Route index element={<LazyUsuarios />} />
          <Route path="new" element={<LazyUsuariosNew />} />
          <Route path="edit/:id" element={<LazyUsuariosEdit />} />
          <Route path="view/:id" element={<LazyUsuariosEdit />} />
          <Route path="remove/:id" element={<LazyUsuariosEdit />} />
        </Route>

        {/* CLIENTES */}
        <Route path="clientes">
          <Route index element={<LazyClientes />} />
          <Route path="new" element={<LazyClientesNew />} />
          <Route path="edit/:id" element={<LazyClientesEdit />} />
          <Route path="view/:id" element={<LazyClientesView />} />
        </Route>

        {/* EQUIPAMENTOS */}
        <Route path="/equipamentos" element={<Equipamentos />} />

        {/* PRODUTOS */}
        <Route path="produtos">
          <Route index element={<LazyProdutos />} />
          <Route path="new" element={<LazyProdutosNew />} />
          <Route path="edit/:id" element={<LazyProdutosEdit />} />
          <Route path="view/:id" element={<LazyProdutosView />} />
        </Route>

        {/* TIPOS PRODUTOS */}
        <Route path="tipos/produtos">
          <Route index element={<LazyTiposProdutos />} />
          <Route path="new" element={<LazyTiposProdutosNew />} />
          <Route path="edit/:id" element={<LazyTiposProdutosEdit />} />
        </Route>

        {/**  ENTRADA DE PRODUTOS */}
        <Route path="entradas/produtos">
          <Route index element={<LazyEntradasProdutos />} />
          <Route path="new" element={<LazyEntradasProdutosNew />} />
          <Route path="edit/:id" element={<LazyEntradasProdutosEdit />} />
          <Route path="view/:id" element={<LazyEntradasProdutosView />} />
        </Route>

        {/** PEDIDOS */}
        <Route path="pedidos">
          <Route index element={<LazyPedidos />} />
          <Route path="new" element={<LazyPedidosNew />} />
          <Route path="edit/:id" element={<LazyPedidosEdit />} />
          <Route path="view/:id" element={<LazyPedidosView />} />
          <Route path="pdf/:id" element={<PedidoPDF />} />
        </Route>

        {/* EMPRESTIMOS */}
        <Route path="/emprestimos">
          <Route index element={<LazyEmprestimos />} />
          <Route path="new" element={<LazyEmprestimosNew />} />
          <Route path="edit/:id" element={<LazyEmprestimosEdit />} />
          <Route path="view/:id" element={<LazyEmprestimosView />} />
          <Route path="pdf/:id" element={<EmprestimoPDF />} />
        </Route>

        {/* TIPOS EQUIPAMENTOS */}
        <Route path="tipos/equipamentos">
          <Route index element={<LazyTiposEquipamentos />} />
          <Route path="new" element={<LazyTiposEquipamentosNew />} />
          <Route path="edit/:id" element={<LazyTiposEquipamentosEdit />} />
        </Route>

        {/* EQUIPAMENTOS */}
        <Route path="/equipamentos">
          <Route index element={<LazyEquipamentos />} />
          <Route path="new" element={<LazyEquipamentosNew />} />
          <Route path="edit/:id" element={<LazyEquipamentosEdit />} />
          <Route path="view/:id" element={<LazyEquipamentosView />} />
        </Route>

        {/* PLANO CONTAS */}
        <Route path="/plano_contas">
          <Route index element={<LazyPlanoContas />} />
          <Route path="new" element={<LazyPlanoContasNew />} />
          <Route path="edit/:id" element={<LazyPlanoContasEdit />} />
        </Route>

        
        {/* PLANO CONTAS */}
        <Route path="/despesas">
          <Route index element={<LazyDespesas />} />
          <Route path="new" element={<LazyDespesasNew />} />
          <Route path="edit/:id" element={<LazyDespesasEdit />} />
          <Route path="view/:id" element={<LazyDespesasView />} />
        </Route>

        {/* KITS EQUIPAMENTOS */}
        <Route path="/kits/equipamentos">
          <Route index element={<LazyKitsEquipamentos />} />
          <Route path="new" element={<LazyKitsEquipamentosNew />} />
          <Route path="edit/:id" element={<LazyKitsEquipamentosEdit />} />
          <Route path="view/:id" element={<LazyKitsEquipamentosView />} />
        </Route>


        {/* RELATÓRIOS */}
        <Route path="/relatorios">
          <Route path="pedidos" element={<LazyRelatorioPedidos />} />
          <Route path="fluxo_caixa" element={<LazyRelatorioFluxoCaixa />} />
          <Route path="movimentacoes" element={<LazyRelatorioMovimentacoes />} />
          <Route path="estoque/produtos" element={<LazyRelatorioEstoqueProdutos />} />
        </Route>

        {/** CONFIGS */}
        <Route path="/config" element={<LazyConfigs />} />
        <Route path="/perfil" element={<LazyPerfil />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}

function RequireAuthAdmin({ role_id }) {
  let { handleAuth } = useAuth();
  let location = useLocation();

  React.useEffect(() => {
    handleAuth();
    // eslint-disable-next-line
  });

  return role_id === 1 ? (
    <Outlet />
  ) : <Navigate to="/" state={{ from: location }} replace={true} />
}
