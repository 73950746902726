import { EncryptStorage } from 'encrypt-storage';


function encryptStorage() {
  if(process.env.REACT_APP_ENCRYPT_KEY){
    const encryptStorage = new EncryptStorage(process.env.REACT_APP_ENCRYPT_KEY, {
      storageType: 'localStorage',
    });

    return encryptStorage;
  }

  return new EncryptStorage("teste", {
    storageType: 'localStorage',
  });
}


export default encryptStorage();