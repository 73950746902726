import React from "react";
import { BrowserRouter } from "react-router-dom";

import Routes from "./Routes/";

//** PROVIDER
import { MyThemeProvider } from "./Contexts/ThemeContext";
import { AuthProvider } from "./Contexts/AuthContext";

function App() {
	// React.Strict

	return (
		<MyThemeProvider>
			<AuthProvider>
				<BrowserRouter>
					<Routes />
				</BrowserRouter>
			</AuthProvider>
		</MyThemeProvider>
	);
}

export default App;
