import { AxiosResponse } from 'axios';
import { Service } from './Services';

// API Error;
import { ApiResponseError } from '../Classes/ApiResponseError'
// Validate
import { AcessoValidate } from '../Utils/validations/Login.schemas';

interface ICredentials {
    login: string,
    password: string
}



export class LoginService extends Service<ICredentials>{

  constructor(path:string){
    super(path);
    this.path = path;
  }

  public async loginUser (credentials: ICredentials): Promise<AxiosResponse> {
    try {
      const Dados = await AcessoValidate<ICredentials>(credentials);
      const response = await this.new(Dados);
      return response;
    } catch (error) {
      console.log(error);
      if(error instanceof ApiResponseError){
        throw error.message
      }
      throw error;
    }
  } 

} 



export const loginService = new LoginService("/login/authenticate");