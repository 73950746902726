import React, { createContext, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

//** CONTEXT
import useSnackBar from './SnackBarContext';
import useLoading from './LoadingContext';

//* SERVICE
import { emprestimosService } from '../Services/Emprestimos.service';
import { kitEquipamentosService } from '../Services/KitEquipamentos.service';
import { pedidosServices } from '../Services/Pedidos.service';
import { clienteService } from '../Services/Clientes.service';

//* Utils
import { DateTimeFormatInput } from '../Utils/dates';

const EmprestimosContext = createContext({});

const EmprestimosProvider = ({ children }) => {
  const { handleSnackBar, navigateSnackBar } = useSnackBar();
  const { setLoadingGet, setLoadingSubmit } = useLoading();
  const [emprestimos, setEmprestimos] = useState([]);
  const [emprestimo, setEmprestimo] = React.useState({
    tipo: null,
  });
  const [errors, setErrors] = useState({});
  const [pedidos, setPedidos] = React.useState([]);
  const [currPedido, setCurrPedido] = React.useState({});
  const [kits, setKits] = React.useState([]);
  const [currKit, setCurrKit] = React.useState({});

  const getEmprestimos = useCallback(async () => {
    try {
      const Dados = await emprestimosService.all();

      if (Dados.success) {
        console.log(Dados.data);
        return setEmprestimos(Dados.data);
      }

      throw Dados.data;
    } catch (error) {
      console.log(error);
      handleSnackBar({
        type: 'error',
        message:
          error && error.message
            ? error.message
            : 'Erro em carregar equipamentos, por favor tente mais tarde.',
      });
    } finally {
      setLoadingGet(false);
    }
    //eslint-disable-next-line
  }, []);

  const getEmprestimo = useCallback(async (id, action) => {
    try {
      const Dados = await emprestimosService.getEmprestimo(parseInt(id));

      if (Dados.data.finalizado && action === 'edit') {
        throw new Error('Empréstimo já finalizado não pode ser editado.');
      }

      const [pedido, revendedor, kit] = await Promise.all([
        Dados.data.pedido_id
          ? await pedidosServices.getPedido(Dados.data.pedido_id)
          : null,
        Dados.data.cliente_id
          ? await clienteService.getCliente(Dados.data.cliente_id)
          : null,
        await kitEquipamentosService.getKitEquipamento(Dados.data.kit_id),
      ]);

      if (Dados.success && kit.success) {
        setEmprestimo({
          ...Dados.data,
          tipo: Dados.data.pedido_id ? 'pedido' : 'revendedor',
          data_entrega: Dados.data.data_entrega
            ? DateTimeFormatInput(Dados.data.data_entrega)
            : null,
          data_devolucao: Dados.data.data_devolucao
            ? DateTimeFormatInput(Dados.data.data_devolucao)
            : null,
          kit: kit.data,
          pedido: Dados.data.pedido_id && pedido.data,
          revendedor: Dados.data.cliente_id && revendedor.data,
        });
        setCurrKit(kit.data);
        Dados.data.pedido_id && setCurrPedido(pedido.data);
        return Dados.data;
      }

      throw Dados.data;
    } catch (error) {
      console.log(error);
      navigateSnackBar('/emprestimos', {
        type: 'error',
        error,
        message: 'Erro em carregar empréstimo, por favor tente mais tarde.',
      });
    } finally {
      setLoadingGet(false);
    }
    //eslint-disable-next-line
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setLoadingSubmit(true);
      console.log(handleAfterSubmit(emprestimo));
      const Dados = await emprestimosService.editEmprestimo(
        handleAfterSubmit(emprestimo)
      );

      if (Dados.success) {
        setErrors({});
        navigateSnackBar('/emprestimos', {
          type: 'success',
          message: 'Emprestimo atualizado com sucesso.',
        });
      }

      throw Dados.data.message;
    } catch (error) {
      console.log(error);
      if (!error.isValid && error.errors) {
        setErrors(error.errors);
      }
      handleSnackBar({
        type: 'error',
        message:
          error && error.message
            ? error.message
            : 'Erro em atualizar o emprestimo.',
      });
    } finally {
      setLoadingSubmit(false);
    }
    //eslint-disable-next-line
  }, [emprestimo]);

  const handleSubmitNew = useCallback(async () => {
    try {
      setLoadingGet(true);
      const Dados = await emprestimosService.newEmprestimo(
        handleAfterSubmit(emprestimo)
      );

      if (Dados.success) {
        navigateSnackBar('/emprestimos', {
          type: 'success',
          message: 'Emprestimo registrado com sucesso.',
        });
      }

      throw Dados.data;
    } catch (error) {
      console.log(error);
      if (!error.isValid && error.errors) {
        setErrors(error.errors);
      }

      handleSnackBar({
        type: 'error',
        message: error
          ? error
          : 'Erro em registrar de empréstimo, verifique os dados.',
      });
    } finally {
      setLoadingGet(false);
    }
    //eslint-disable-next-line
  }, [emprestimo]);

  const handleAfterSubmit = useCallback((Dados) => {
    if (Dados.tipo === 'revendedor') {
      return {
        id: Dados.id,
        tipo: Dados.tipo,
        data_entrega: Dados.data_entrega,
        data_devolucao: Dados.data_devolucao,
        cliente_id: Dados.revendedor.id,
        kit_id: Dados.kit_id,
      };
    } else if (Dados.tipo === 'pedido') {
      return {
        id: Dados.id,
        tipo: Dados.tipo,
        pedido_id: Dados.pedido.id,
        kit_id: Dados.kit_id,
      };
    }

    throw new Error('Não pode salvar emprestimo sem definir o tipo.');
  }, []);

  const handleFinalizaEmprestimo = useCallback(async (id) => {
    // no-restricted-globals
    if (
      window.confirm(`Deseja finalizar o emprestimo do kit?`) === true
    ) {
      try {
        setLoadingSubmit(true);
        const Dados = await emprestimosService.finalizaEmprestimo(id);

        console.log(Dados);

        if (Dados.success) {
          handleSnackBar({
            type: 'success',
            message: 'Emprestimo finalizado com sucesso.',
          });
          setErrors({});
          return setEmprestimos(emprestimos => emprestimos.map(e => e.id === Dados.data.id ? Dados.data : e ))
        }

        throw Dados.data.message;
      } catch (error) {
        console.log(error);
        if (!error.isValid && error.errors) {
          setErrors(error.errors);
        }
        handleSnackBar({
          type: 'error',
          message:
            error && error.message
              ? error.message
              : 'Erro em finalizar o emprestimo.',
        });
      } finally {
        setLoadingSubmit(false);
      }
    } 

    return;
    //eslint-disable-next-line
  }, []);

  return (
    <EmprestimosContext.Provider
      value={{
        emprestimos,
        emprestimo,
        setEmprestimo,
        pedidos,
        setPedidos,
        currPedido,
        setCurrPedido,
        kits,
        setKits,
        currKit,
        setCurrKit,
        errors,
        setErrors,
        getEmprestimos,
        getEmprestimo,
        handleSubmitNew,
        handleSubmit,
        handleFinalizaEmprestimo,
      }}
    >
      {children}
    </EmprestimosContext.Provider>
  );
};

EmprestimosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useEquipamentos() {
  return useContext(EmprestimosContext);
}

export { EmprestimosContext, EmprestimosProvider };
