import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { IResponseSuccess } from 'src/Services/Api.service';

export default abstract class BaseApi<T> {
  protected abstract get(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<IResponseSuccess<T[] | T>>>;

  protected abstract delete<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<IResponseSuccess<T>>>;

  protected abstract post(
    url: string,
    data: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<IResponseSuccess<T>>>;

  protected abstract put(
    url: string,
    data: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<IResponseSuccess<T>>>;

	protected abstract request<T, R = AxiosResponse<T>>(
		config: AxiosRequestConfig
	): Promise<R>;

	protected abstract success<T>(response: AxiosResponse<T>): T;

	protected abstract error(error: AxiosError<any>): void;

}
