import * as yup from "yup";
import Validate from "./Validate";

export async function newPedidoKitSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
    pedido_id: yup.number().required("Pedido é requerido."),
    kit_id: yup.number().required("Kit é requerido"),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}


export async function newRevendedorKitSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
		data_entrega: yup.date().required("Data da Entrega é obrigatório"),
		data_devolucao: yup.date().required("Data da Devolução é obrigatório"),
    cliente_id: yup.number().required("Revendedor é requerido."),
    kit_id: yup.number().required("Kit é requerido"),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}

export async function updatePedidoKitSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
    id: yup.number().required(),
    pedido_id: yup.number().required("Pedido é requerido."),
    kit_id: yup.number().required("Kit é requerido"),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}


export async function updateRevendedorKitSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
    id: yup.number().required(),
		data_entrega: yup.date().required("Data da Entrega é obrigatório"),
		data_devolucao: yup.date().required("Data da Devolução é obrigatório"),
    cliente_id: yup.number().required("Revendedor é requerido."),
    kit_id: yup.number().required("Kit é requerido"),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}