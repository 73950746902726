import DashboardIcon from "@mui/icons-material/Dashboard";
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';


import getIcon from '../Store/Icons'

const MenuInit = [
	{
		Icon: null,
		iconIfy: getIcon("Dashboard"),
		primary: "Dashboard",
		url: "/",
		isOpen: false,
		access: [1,4,5],
		subMenu: [],
		divider: true,
	},
	{
		Icon: null,
		iconIfy: getIcon("Entrada Produtos"),
		primary: "Entrada Produtos",
		url: "/entradas/produtos",
		isOpen: false,
		access: [1],
		subMenu: [],
	},
	{
		iconIfy: getIcon("Despesas"),
		primary: "Despesas",
		url: "/despesas",
		isOpen: false,
		access: [1],
		subMenu: [],
		divider: true,
	},
	{
		Icon: null,
		iconIfy: getIcon("Pedidos"),
		primary: "Pedidos",
		url: "/pedidos",
		isOpen: false,
		access: [1,4,5],
		subMenu: [],
		divider: true,
	},
	{
		Icon: null,
		iconIfy: getIcon("Empréstimos"),
		primary: "Empréstimos",
		url: "/emprestimos",
		isOpen: false,
		access: [1,4,5],
		subMenu: [],
		divider: false,
	},
	{
		Icon: DashboardIcon,
		primary: "Gestão de Equipamentos",
		url: "#",
		isOpen: false,
		access: [1],
		subMenu: [
			{
				Icon: DashboardCustomizeIcon,
				primary: "Kits de Equipamentos",
				url: "/kits/equipamentos",
				isOpen: false,
				access: [1],
				subMenu: [],
			},
			{
				Icon: DashboardIcon,
				primary: "Equipamentos",
				url: "/equipamentos",
				isOpen: false,
				access: [1],
				subMenu: [],
			},
			{
				Icon: DeveloperBoardIcon,
				primary: "Tipos Equipamentos",
				url: "/tipos/equipamentos",
				isOpen: false,
				access: [1],
				subMenu: [],
			},
		],
	},
	{
		Icon: null,
		iconIfy: getIcon("Gestão de Produtos"),
		primary: "Gestão de Produtos",
		url: "/produtos",
		access: [1],
		divider: true,
		subMenu: [
			{
				Icon: null,
				iconIfy: getIcon("Produtos"),
				primary: "Produtos",
				url: "/produtos",
				isOpen: false,
				access: [1],
				subMenu: [],
			},
			{
				Icon: null,
				iconIfy: getIcon("Tipos de Produtos"),
				primary: "Tipos de Produtos",
				url: "/tipos/produtos",
				isOpen: false,
				access: [1],
				subMenu: [],
			},
		],
	},
	{
		iconIfy: getIcon("Clientes"),
		primary: "Clientes",
		url: "/clientes",
		isOpen: false,
		access: [1],
		subMenu: [],
	},
	{
		iconIfy: getIcon("Usuários"),
		primary: "Usuários",
		url: "/usuarios",
		isOpen: false,
		access: [1],
		subMenu: [],
	},
	{
		iconIfy: getIcon("Plano Contas"),
		primary: "Plano de Contas",
		url: "/plano_contas",
		isOpen: false,
		access: [1],
		subMenu: [],
		divider: true,
	},

	{
		iconIfy: getIcon("Relatórios"),
		primary: "Relatórios",
		url: "#",
		access: [1],
		subMenu: [
			{
				Icon: null,
				iconIfy: getIcon("Relatórios"),
				primary: "Relatório de Pedidos",
				url: "/relatorios/pedidos",
				isOpen: false,
				access: [1],
			},
			{
				Icon: null,
				iconIfy: getIcon("Fluxo de Caixa"),
				primary: "Fluxo de Caixa",
				url: "/relatorios/fluxo_caixa",
				isOpen: false,
				access: [1],
			},
			{
				Icon: null,
				iconIfy: getIcon("Movimentações"),
				primary: "Movimentações",
				url: "/relatorios/movimentacoes",
				isOpen: false,
				access: [1],
			},
			{
				Icon: null,
				iconIfy: getIcon("Estoque"),
				primary: "Estoque de Produtos",
				url: "/relatorios/estoque/produtos",
				isOpen: false,
				access: [1],
			},
		],
	},
];

export default MenuInit;
