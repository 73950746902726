import * as React from "react";
import Box from "@mui/material/Box";
import {
	FormHelperText,
	FormControl,
	OutlinedInput,
	InputLabel,
	InputAdornment,
	IconButton,
	Typography
} from "@mui/material/";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//* COMPONENTS
import Alert from '../../Components/Alerts/';
import ButtonLoading from '../../Components/Button/Loading';

//* CONTEXT
import useLogin from "../../Contexts/LoginContext";

//* VALIDATE
// import {
// 	LoginPathValidate,
// 	PasswdPathValidate,
// } from "../../Utils/validations/Login.schemas";

export default function FormLogin() {
	const { values, setValues, errors, alerta, loading, handleSubmit } = useLogin();

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleBlur = (prop) => () => {
		// const resp = 'login' === prop ? LoginPathValidate(values[prop]) : PasswdPathValidate(values[prop])
		// if(!resp.isValid){
		// 	return setErrors(e => ({
		// 		...e,
		// 		[prop]: resp.error
		// 	}))
		// }
		// setErrors(e => {
		// 	delete e[prop]
		// })
	};

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<Box
		component="form"
			onSubmit={handleSubmit}
			noValidate
			sx={{
				width: '100%',
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
			>
			<img src="/static/images/logos/logo_laranja.png" loading="lazy" alt="logo" width="250px" />
			<Typography component="h5" variant="h5" sx={{ mt: 4, fontWeight: "bold" }} >Bem Vindo</Typography>
			<Typography sx={{ mt: 1, mb: 1 }}>Entre com suas credenciais para continuar </Typography>

			{alerta && <Alert type="error" >{alerta}</Alert>} 
			<FormControl
				fullWidth
				error={errors && errors["login"] ? true : false}
				variant="outlined"
				sx={{ mt: 2 }}
			>
				<InputLabel required htmlFor="outlined-login">
					Usuário
				</InputLabel>
				<OutlinedInput
					id="outlined-login"
					type="text"
					value={values.login}
					required
					fullWidth
					onBlur={handleBlur("login")}
					onChange={handleChange("login")}
					label="Usuário"
				/>
				<FormHelperText id="component-error-text">
					{errors && errors["login"]}
				</FormHelperText>
			</FormControl>
			<FormControl
				fullWidth
				error={errors && errors["passwd"] ? true : false}
				variant="outlined"
				sx={{ mt: 1 }}
			>
				<InputLabel required htmlFor="outlined-adornment-password">
					Senha
				</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={values.showPassword ? "text" : "password"}
					value={values.passwd}
					required
					fullWidth
					onChange={handleChange("passwd")}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{values.showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					label="passwd"
				/>
				<FormHelperText id="component-error-text">
					{errors && errors["passwd"]}
				</FormHelperText>
			</FormControl>
			<ButtonLoading
				loading={loading}
				type="submit"
				color="secondary"
				fullWidth
				sx={{ mt: 3, mb: 2, }}
			>
				Acessar
			</ButtonLoading>
		</Box>
	);
}
