import React, {
  createContext,
  useContext,
} from "react";
import PropTypes from "prop-types";

//* HOOKS
import useLocalStore from "../Hooks/useLocalStore";

//* SERVICE
import { loginService } from "../Services/Login.services";

const LoginContext = createContext({});

const LoginProvider = ({ children }) => {
  const { setData, setDataEncrypt } = useLocalStore();
  const [values, setValues] = React.useState({
		login: "",
		passwd: "",
		showPassword: false,
	});
  const [errors, setErrors] = React.useState([]);
  const [alerta, setAlerta] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {

    try {
      setLoading(true);
      event.preventDefault();
      const resp = await loginService.loginUser(values);
      if(resp.success){
        setData('token', resp.token);
        setDataEncrypt('user', {
          nome: resp.nome,
          role_id: resp.role_id
        });
        setData("cliente", resp.cliente)
        window.location.href = '/';
        return;
      }

      throw resp.data;
    } catch (error) {
      console.log(error);
      if(error.isValid === false){
        return setErrors(error.errors)
      }
      setAlerta(typeof error === "string" ? error : error.message ? error.message : "Erro em fazer o login.");
      setErrors([])
    } finally {
      setLoading(false);
    }
  }
  

  return (
    <LoginContext.Provider
      value={{
        values,
        setValues,
        errors,
        setErrors,
        alerta,
        setAlerta,
        loading,
        handleSubmit,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

LoginProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useLogin() {
  return useContext(LoginContext);
}

export { LoginContext, LoginProvider };
