import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

// TEMPLATES
import AppBar from '../Templates/AppBar';

//* ROTAS
import RoutesAuth from './RoutesAuth'
import Routes from './Routes'

//* PROVIDER
import { SnackBarProvider } from '../Contexts/SnackBarContext';
import { MenuProvider } from '../Contexts/MenuContext';
import { UserProvider } from '../Contexts/UserContext';

//* CONTEXT
import useAuth from '../Contexts/AuthContext';

function Loading() {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#201f1e',
      }}
      open={true}
    >
      <img src="/static/images/logos/loader.gif" alt="loader" />
    </Backdrop>
  );
}

export default function Index() {
  const { logado } = useAuth();

  return (
    !logado ? <Routes /> : (
      <React.Suspense fallback={<Loading />}>
        <UserProvider>

          <SnackBarProvider>
            <Box sx={{ display: 'flex' }}>
              <MenuProvider>
                <AppBar />
              </MenuProvider>
              <RoutesAuth />
            </Box>
          </SnackBarProvider>
        </UserProvider>
      </React.Suspense>
    )
  )
}